import React from 'react';
import { Container, Grid } from '@mui/material';
import { useLegends } from '../../../hooks/useLegends';

const LineLegendDisplay = () => {
    const { lineLegends } = useLegends();
    return lineLegends
        ?.filter((e) => !e.goal)
        .map((e) => {
            return (
                <React.Fragment key={e.id}>
                    <Container sx={{ display: 'inline-block', whiteSpace: 'nowrap', width: '33%' }}>
                        <svg
                            style={{
                                backgroundColor: e.color,
                                width: '10px',
                                height: '10px',
                                marginRight: '5px',
                                marginLeft: '2px',
                            }}
                        />
                        <Grid display="inline" item xs={12}>
                            {e.id}
                        </Grid>
                    </Container>
                </React.Fragment>
            );
        });
};

export default LineLegendDisplay;
