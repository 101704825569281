import {
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material';
import React from 'react';
import { filterJsonConstantsByMeasure } from '../../../helpers/esgUnitConversions';
import { useDarkMode } from '../../../hooks/useDarkMode';

const isChecked = (panelConfig, id) => {
    if (!panelConfig?.fields) {
        return false;
    }
    return panelConfig.fields.map((f) => f.id).includes(id);
};

const EsgFieldsTable = ({ esgFields, panelConfig, setPanelConfig }) => {
    const { darkMode } = useDarkMode();
    const darkModeColor = darkMode ? '#FFFFFF80' : '#00000061';

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell width="20%">Show</TableCell>
                    <TableCell width="40%">Field</TableCell>
                    <TableCell width="40%">Unit</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {esgFields.map((field) => (
                    <TableRow key={field.id}>
                        <TableCell>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isChecked(panelConfig, field.id)}
                                        onChange={(e) => {
                                            const fields = panelConfig?.fields || [];
                                            if (e.target.checked) {
                                                fields.push({
                                                    id: field.id,
                                                    measure: field.defaultMeasure || field.measure,
                                                });
                                            } else {
                                                fields.splice(
                                                    fields.findIndex((f) => f.id === field.id),
                                                    1
                                                );
                                            }
                                            setPanelConfig({
                                                ...panelConfig,
                                                fields,
                                            });
                                        }}
                                    />
                                }
                            />
                        </TableCell>
                        <TableCell
                            sx={{
                                color: !isChecked(panelConfig, field.id) ? darkModeColor : '',
                            }}
                        >
                            {field.label}
                        </TableCell>
                        <TableCell>
                            <FormControl size="small" fullWidth>
                                <InputLabel id="measure-select" />
                                <Select
                                    disabled={!isChecked(panelConfig, field.id)}
                                    labelId="measure-select"
                                    className="question-field"
                                    value={
                                        panelConfig.fields?.find((f) => f.id === field.id)
                                            ?.measure || field.defaultMeasure
                                    }
                                    size="small"
                                    onChange={(e) => {
                                        const newValue = panelConfig.fields.map((f) => {
                                            if (f.id === field.id) {
                                                return {
                                                    ...f,
                                                    measure: e.target.value,
                                                };
                                            }
                                            return f;
                                        });
                                        setPanelConfig({
                                            ...panelConfig,
                                            fields: newValue,
                                        });
                                    }}
                                >
                                    {filterJsonConstantsByMeasure(
                                        panelConfig.fields?.find((f) => f.id === field.id)
                                            ?.measure || field.defaultMeasure
                                    ).map((item) => (
                                        <MenuItem key={item.name} value={item.name}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default EsgFieldsTable;
