import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import BarChart from './charts/BarChart';
import { convertUnitGToKg } from '../../../helpers/convertUnits';
import recursiveSumChildren from './helpers/recursiveSumChildren';
import getScopes from './helpers/getScopes';

const convertAndSetBarchart = (treeWithSum) => {
    const forBarchart = [];
    if (!treeWithSum.children.length) {
        return forBarchart;
    }
    treeWithSum.children.forEach((scope) => {
        const shortScopeName = getScopes().find(
            (allScope) => allScope.name === scope.name
        ).shortName;
        scope.children.forEach((category) => {
            forBarchart.push({
                category: `${category.name} (${shortScopeName})`,
                sum: Math.round(convertUnitGToKg(category.sum) / 1000),
            });
        });
    });
    return forBarchart.sort((a, b) => b.sum - a.sum);
};

const ByCategory = ({ yearSumData, setChartData, isPreview = false }) => {
    // extend the tree with sum
    const treeWithSum = recursiveSumChildren({ children: yearSumData });
    // convert and set BarchartData
    const barchartData = convertAndSetBarchart(treeWithSum);

    useEffect(() => {
        if (setChartData) {
            setChartData(barchartData);
        }
    }, [yearSumData]);

    return (
        <Grid item xs={12}>
            {barchartData?.length === 0 ? (
                <Grid item>
                    <h5>No data available</h5>
                </Grid>
            ) : (
                <BarChart
                    data={barchartData}
                    keys={['sum']}
                    indexBy="category"
                    axisLeftLegend="CO2e (mt)"
                    legendOffset={-55}
                    isPreview={isPreview}
                />
            )}
        </Grid>
    );
};

export default ByCategory;
