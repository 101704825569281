import { Alert, Grid, Typography } from '@mui/material';
import React from 'react';
import CommonButton from './CommonButton';

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

const DateRangeAlert = ({ values, setShowDate, year, setFieldValue, setUsedDateRange }) => {
    if (!values?.emission_description) {
        return null;
    }
    const foundMonth = months.find((month) =>
        values.emission_description.toLowerCase().includes(month.toLowerCase())
    );

    if (!foundMonth || (values.from_date && values.to_date)) {
        return null;
    }

    return (
        <Grid item xs={12}>
            <Alert severity="info">
                <Grid container alignItems="center">
                    <Grid item>
                        <Typography variant="body2">
                            It seems that your emission description contains the month{' '}
                            <strong>{foundMonth}</strong>. Would you like to use it as a date range?
                        </Typography>
                    </Grid>
                    <Grid item>
                        <CommonButton
                            buttonType="outlined"
                            label="Use as date range"
                            onClick={() => {
                                setShowDate(true);
                                const monthIndex = months.findIndex((month) =>
                                    values.emission_description
                                        .toLowerCase()
                                        .includes(month.toLowerCase())
                                );
                                const firstDay = new Date(year, monthIndex, 1);
                                const lastDay = new Date(year, monthIndex + 1, 0);
                                const formatDate = (date) =>
                                    `${year}-${String(date.getMonth() + 1).padStart(
                                        2,
                                        '0'
                                    )}-${String(date.getDate()).padStart(2, '0')}`;
                                setFieldValue('from_date', formatDate(firstDay));
                                setFieldValue('to_date', formatDate(lastDay));
                                setUsedDateRange(true);
                            }}
                        />
                    </Grid>
                </Grid>
            </Alert>
        </Grid>
    );
};

export default DateRangeAlert;
