import { IconButton, TableCell, Tooltip, Typography, Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CheckIcon from '@mui/icons-material/Check';
import { formatNumber } from '../../../helpers/convertUnits';
import ProgressSvg from '../../../components/atom/ProgressSvg';
import getMeasureValue from '../helpers/getMeasureValue';

function FieldValue({ field, row }) {
    const [fieldValue, setFieldValue] = useState('');

    useEffect(() => {
        const fieldType = row.esg_question_fields.find((e) => e.id === field?.id)?.type;
        if (fieldType === 'number') {
            setFieldValue(formatNumber(field?.value));
        } else {
            setFieldValue(field?.value);
        }
    }, [field, row]);

    return field.value ? (
        <Box display="flex" alignItems="center">
            <Typography variant="caption">
                {fieldValue} {getMeasureValue(field)}
            </Typography>
        </Box>
    ) : (
        <Typography variant="caption">-</Typography>
    );
}

const ValuesTableCell = ({ row, darkMode }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <TableCell
            colSpan={10}
            style={{
                margin: 0,
                padding: 0,
                wordWrap: 'break-word',
                whiteSpace: 'normal',
            }}
        >
            <Box
                display="flex"
                alignItems="center"
                gap={1}
                style={{
                    borderLeft: `32px solid ${!darkMode ? '#F7FAFC' : '#1f2c3b'}`,
                    paddingTop: 4,
                    paddingLeft: 8,
                    paddingBottom: 4,
                }}
            >
                {!isExpanded &&
                    (row.esg_question_fields.length > 0 ? (
                        <>
                            <Grid item style={{ position: 'relative', width: 30 }}>
                                <ProgressSvg
                                    size={30}
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        transform: 'translateY(-50%)',
                                    }}
                                    value={(row.field_filled_out / row.field_count) * 100}
                                />
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        transform: 'translateY(-50%)',
                                        left: 0,
                                        right: 0,
                                        fontSize: 10,
                                        textAlign: 'center',
                                    }}
                                >
                                    {row.mark_completed ? (
                                        <CheckIcon
                                            color="success"
                                            style={{
                                                paddingTop: 2,
                                            }}
                                        />
                                    ) : null}
                                    {!row.mark_completed && row.field_filled_out > 0
                                        ? `${row.field_filled_out}/${row.field_count}`
                                        : null}
                                </div>
                            </Grid>
                            <Grid item>
                                {row.esg_question_fields.map((field) => {
                                    const hasAnswer = row.values.find(
                                        (e) => e.id === field.id && e.value
                                    );
                                    return (
                                        <Typography
                                            key={field.id}
                                            variant="caption"
                                            style={{
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                display: 'block',
                                                whiteSpace: 'nowrap',
                                                fontSize: '1.1rem',
                                                lineHeight: '1.2',
                                                color: hasAnswer
                                                    ? `${!darkMode ? 'black' : 'white'}`
                                                    : '#B0B0B0',
                                                maxWidth: '800px',
                                            }}
                                        >
                                            {field.label}
                                        </Typography>
                                    );
                                })}
                            </Grid>
                            <Grid item style={{ flexGrow: 1, textAlign: 'right' }}>
                                <Tooltip title="Show answers" placement="right">
                                    <IconButton
                                        disableRipple
                                        onClick={() => setIsExpanded(!isExpanded)}
                                    >
                                        <ExpandMoreIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </>
                    ) : (
                        <Typography variant="caption">No questions yet</Typography>
                    ))}
                {isExpanded && (
                    <>
                        <Box>
                            {row.values.length > 0 ? (
                                row.values.map((field, index) => (
                                    <Box key={index} mb={1}>
                                        <Typography variant="subtitle1">
                                            {
                                                row.esg_question_fields.find(
                                                    (e) => e.id === field?.id
                                                )?.label
                                            }
                                            :
                                        </Typography>
                                        <FieldValue
                                            field={field}
                                            row={row}
                                            isExpanded={isExpanded}
                                        />
                                    </Box>
                                ))
                            ) : (
                                <Typography variant="caption">No answers available</Typography>
                            )}
                        </Box>
                        <Grid item style={{ flexGrow: 1, textAlign: 'right' }}>
                            <Tooltip title="Hide answers">
                                <IconButton
                                    disableRipple
                                    size="small"
                                    onClick={() => {
                                        setIsExpanded(false);
                                    }}
                                >
                                    <ExpandLessIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </>
                )}
            </Box>
        </TableCell>
    );
};

export default ValuesTableCell;
