import {
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material';
import React, { useCallback } from 'react';
import Add from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { jsonConstants } from '../../../helpers/esgUnitConversions';
import CommonButton from '../../../components/atom/CommonButton';
import { MODAL_TYPE, useModals } from '../../../hooks/useModal';
import { useGlobalFilters } from '../../../hooks/useGlobalFilters';

const EsgQuestionFieldEditor = ({ values, setFieldValue }) => {
    const { showModal } = useModals();
    const { year } = useGlobalFilters();
    const { fields } = values;
    const setField = useCallback(
        (newValue) => {
            setFieldValue('fields', newValue);
        },
        [setFieldValue]
    );

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell width="40%">Label</TableCell>
                    <TableCell width="20%">Type</TableCell>
                    <TableCell width="20%">Default measure</TableCell>
                    <TableCell width="15%">Connect GHG</TableCell>
                    <TableCell width="5%">&nbsp;</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {fields.map(({ id, label, type, defaultMeasure }, fieldIdx) => (
                    <TableRow key={id}>
                        <TableCell>
                            <TextField
                                fullWidth
                                type="text"
                                autoComplete="off"
                                value={label}
                                label="field label"
                                required
                                onChange={(ev) => {
                                    setField(
                                        fields.map((line) => {
                                            if (line.id === id) {
                                                return {
                                                    id,
                                                    label: ev.target.value,
                                                    type: line.type,
                                                    defaultMeasure: line.defaultMeasure,
                                                    // don't remove connected GHG if type label is changed
                                                    ghg: line.ghg,
                                                    use: line.use,
                                                };
                                            }
                                            return line;
                                        })
                                    );
                                }}
                            />
                        </TableCell>
                        <TableCell>
                            <FormControl fullWidth>
                                <InputLabel>{`${label} type`}</InputLabel>
                                <Select
                                    value={type}
                                    required
                                    onChange={(ev) =>
                                        setField(
                                            fields.map((line) => {
                                                if (line.id === id) {
                                                    return {
                                                        id,
                                                        label: line.label,
                                                        type: ev.target.value,
                                                        // default measure should be 'Without unit' for text fields
                                                        defaultMeasure:
                                                            ev.target.value === 'text'
                                                                ? 'Without unit'
                                                                : line.defaultMeasure,
                                                        // remove connected GHG if type is changed
                                                        ghg:
                                                            ev.target.value === 'text'
                                                                ? []
                                                                : line.ghg,
                                                    };
                                                }
                                                return line;
                                            })
                                        )
                                    }
                                >
                                    <MenuItem value="text">Text</MenuItem>
                                    <MenuItem value="number">Number</MenuItem>
                                </Select>
                            </FormControl>
                        </TableCell>
                        <TableCell>
                            <FormControl fullWidth>
                                <InputLabel>Default measure</InputLabel>
                                <Select
                                    value={defaultMeasure}
                                    required
                                    onChange={(ev) =>
                                        setField(
                                            fields.map((line) => {
                                                if (line.id === id) {
                                                    return {
                                                        id,
                                                        label: line.label,
                                                        type: line.type,
                                                        defaultMeasure: ev.target.value,
                                                        ghg: line.ghg,
                                                        use: line.use,
                                                    };
                                                }
                                                return line;
                                            })
                                        )
                                    }
                                    disabled={type !== 'number'}
                                >
                                    {jsonConstants.map((value) => (
                                        <MenuItem value={value.name}>{value.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </TableCell>
                        <TableCell>
                            <CommonButton
                                onClick={() => {
                                    showModal(MODAL_TYPE.CONNECT_GHG, {
                                        title: `Connect GHG to ${label}`,
                                        content:
                                            'If you change the field type, the connected GHG will be removed.',
                                        fields,
                                        fieldIdx,
                                        setField,
                                        year,
                                    });
                                }}
                                label={
                                    // connect or connected
                                    fields[fieldIdx].ghg && fields[fieldIdx].ghg.length > 0 ? (
                                        <span style={{ color: 'green' }}>
                                            <b>Connected</b>
                                        </span>
                                    ) : (
                                        'Connect'
                                    )
                                }
                                buttonType="text"
                                disabled={type !== 'number'} // only allow to connect GHG to number fields
                            />
                        </TableCell>
                        <TableCell>
                            <IconButton
                                onClick={() => {
                                    setField(fields.filter((e) => e.id !== id));
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
                <TableRow>
                    <TableCell colSpan={4} />
                    <TableCell>
                        <IconButton
                            onClick={() => {
                                setField([
                                    ...fields,
                                    {
                                        id: `${Math.random()}`.substring(2),
                                        label: '',
                                        type: 'text',
                                        // set default measure to 'Without unit' for text fields
                                        defaultMeasure:
                                            jsonConstants[jsonConstants.length - 1].name,
                                        // ghg and use are not needed for text fields
                                    },
                                ]);
                            }}
                        >
                            <Add />
                        </IconButton>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};

export default EsgQuestionFieldEditor;
