import React, { useCallback, useEffect, useState } from 'react';
import {
    DialogActions,
    DialogContentText,
    DialogContent,
    DialogTitle,
    Dialog,
    Grid,
    CircularProgress,
    Paper,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Alert,
} from '@mui/material';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import useNotify from '../hooks/useNotify';
import CommonButton from '../components/atom/CommonButton';
import { transformHierarchyToItem } from '../helpers/groups';
import { doSwaggerCall } from '../hooks/useApi';
import recursiveTreeFilter from '../helpers/recursiveTreeFilter';
import getCheckedChildrenNumberRecursive from '../pages/companies/components/helpers/getCheckedChildrenNumberRecursive';
import SearchInput from '../components/atom/SearchInput';
import CategoryLevel from '../components/requirementshierarchy/components/CategoryLevel';

const ConnectGhgModal = ({
    title,
    content,
    fields,
    fieldIdx,
    setField,
    year,
    showModal,
    MODAL_TYPE,
}) => {
    const closeAction = () => showModal(MODAL_TYPE.NONE);
    const [dataGroups, setDataGroups] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const { notifyError } = useNotify();
    const [checkedData, setCheckedData] = useState(fields[fieldIdx]?.ghg || []);
    const [selectedMeasure, setSelectedMeasure] = useState('CO2e');

    const loadHierarchy = useCallback((targetYear) => {
        setLoading(true);
        doSwaggerCall('Hierarchy', 'getSourceHierarchy', {
            year: targetYear,
            hasanydatalines: false,
            hasFactor: false,
        })
            .then((hierarchy) => {
                const transformResult = hierarchy.map((e) => transformHierarchyToItem(e));
                setDataGroups(transformResult);
                setLoading(false);
            })
            .catch((error) => {
                notifyError(error);
            });
    }, []);

    useEffect(() => {
        loadHierarchy(year);
    }, [year]);

    // search all hierarchy
    useEffect(() => {
        if (search === '') {
            setFilteredData(dataGroups);
            return;
        }
        setFilteredData(recursiveTreeFilter(dataGroups, search.toLowerCase()));
    }, [search, dataGroups]);

    const getCheckedChildrenNumber = useCallback(
        (item) => {
            return getCheckedChildrenNumberRecursive(checkedData, item);
        },
        [checkedData]
    );

    const confirm = (selectedData) => {
        // check base units if needed
        if (selectedMeasure === 'Base unit') {
            const sourceIds = selectedData.filter((e) => e.type === 'source').map((e) => e.id);
            doSwaggerCall('EmissionSources', 'getEmissionSources', {
                idList: sourceIds,
                limit: sourceIds.length,
            })
                .then((res) => {
                    // if base units are different, show error
                    const baseUnits = [...new Set(res.emissionSources.map((e) => e.base_unit))];
                    if (res.emissionSources && baseUnits.length > 1) {
                        notifyError(
                            'Only same base unit can be connected. Selected sources have different base unit.'
                        );
                    } else {
                        // if base units are the same, connect
                        setField([
                            ...fields.slice(0, fieldIdx),
                            {
                                ...fields[fieldIdx],
                                ghg: selectedData,
                                use: selectedMeasure, // need to add this way to keep selectedData separate for filter
                            },
                            ...fields.slice(fieldIdx + 1),
                        ]);
                        showModal(MODAL_TYPE.NONE);
                    }
                })
                .catch((error) => {
                    notifyError(error);
                });
        } else {
            setField([
                ...fields.slice(0, fieldIdx),
                {
                    ...fields[fieldIdx],
                    ghg: selectedData,
                    use: selectedMeasure, // need to add this way to keep selectedData separate for filter
                },
                ...fields.slice(fieldIdx + 1),
            ]);
            showModal(MODAL_TYPE.NONE);
        }
    };

    return (
        <Dialog
            open
            maxWidth="md"
            fullWidth
            onClose={closeAction}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {content}
                    {selectedMeasure === 'Base unit' && (
                        <Alert sx={{ mt: 1 }} severity="info">
                            Only sources with the same base unit can be connected.
                        </Alert>
                    )}
                    {fields[fieldIdx]?.ghg?.length > 0 && (
                        <Alert sx={{ mt: 1 }} severity="info">
                            There are connected sources
                        </Alert>
                    )}
                </DialogContentText>
                <Grid>
                    <Paper sx={{ p: 2, width: '100%' }}>
                        <Grid container>
                            {loading && (
                                <Grid item xs={12}>
                                    <CircularProgress size="1.5rem" />
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="measure">Measure</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="measure"
                                        value={selectedMeasure}
                                        size="small"
                                        label="Measure"
                                        onChange={(e) => {
                                            setSelectedMeasure(e.target.value);
                                        }}
                                    >
                                        <MenuItem value="CO2e">CO2e</MenuItem>
                                        <MenuItem value="Base unit">Base unit</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <SearchInput fullWidth search={search} setSearch={setSearch} />
                            </Grid>
                            {filteredData.map((scope) => (
                                <Grid item xs={12} key={`${scope.id} - ${scope.name}`}>
                                    {scope.name}
                                    {scope.children.map((category) => (
                                        <TreeView
                                            key={`${scope.name} - ${category.id} - ${category.name}`}
                                            aria-label="icon expansion"
                                            defaultCollapseIcon={<ExpandMoreIcon />}
                                            defaultExpandIcon={<ChevronRightIcon />}
                                            defaultExpanded={[]}
                                        >
                                            <CategoryLevel
                                                key={`${category.id} - ${category.name}`}
                                                checkedData={checkedData}
                                                category={category}
                                                setCheckedData={setCheckedData}
                                                getCheckedChildrenNumber={getCheckedChildrenNumber}
                                            />
                                        </TreeView>
                                    ))}
                                </Grid>
                            ))}
                        </Grid>
                    </Paper>
                </Grid>
            </DialogContent>
            <DialogActions>
                <CommonButton
                    onClick={closeAction}
                    autoFocus
                    label="Cancel"
                    buttonType="secondary"
                />
                <CommonButton
                    onClick={() => {
                        confirm(checkedData);
                    }}
                    label="Save"
                    buttonType="primary"
                />
            </DialogActions>
        </Dialog>
    );
};

export default ConnectGhgModal;
