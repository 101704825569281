import { Tooltip } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import React, { useEffect, useState } from 'react';
import { useDarkMode } from '../../hooks/useDarkMode';
import { useGuideBoxSidebar } from '../../hooks/useGuideBoxSidebar';
import { doSwaggerCall } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';

const EsgHelperIcon = ({ title, guideNote, esgQuestionId }) => {
    const { darkMode } = useDarkMode();
    const { openSidebar } = useGuideBoxSidebar();
    const { notifyError } = useNotify();
    const [loading, setLoading] = useState(true);
    const [guide, setGuide] = useState(guideNote);

    // silent check if we have the guide note, so we can render the icon
    useEffect(() => {
        setLoading(true);
        doSwaggerCall('EsgQuestions', 'getEsgQuestionGuideNotes', {
            id: esgQuestionId,
        })
            .then((data) => {
                if (data.guide_note !== '' && data.guide_note !== '\n') {
                    setGuide(data.guide_note);
                    setLoading(false);
                }
            })
            .catch((error) => {
                notifyError(error);
                setLoading(false);
            });
    }, [esgQuestionId, notifyError]);

    if (loading) {
        return null;
    }

    return (
        <sup>
            <Tooltip
                title="Click here to open guidance"
                sx={{ cursor: 'pointer' }}
                placement="right"
                onClick={() => openSidebar({ title, content: guide })}
            >
                <HelpOutlineIcon
                    style={{
                        fontSize: '15px',
                        color: !darkMode ? 'grey' : 'white',
                    }}
                />
            </Tooltip>
        </sup>
    );
};

export default EsgHelperIcon;
