import { Button, Grid, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { doSwaggerCall } from '../../hooks/useApi';
import { useGlobalFilters } from '../../hooks/useGlobalFilters';
import SearchInput from '../../components/atom/SearchInput';
import { useAuth } from '../../hooks/useAuth';
import DatalineGroup from './components/DatalineGroup';
import { DATALINE_GROUPS, GROUPBY_OPTIONS, transformHierarchyToItem } from '../../helpers/groups';
import { MODAL_TYPE, useModals } from '../../hooks/useModal';
import useNotify from '../../hooks/useNotify';
import { PERMISSIONS } from '../../helpers/constans';
import GroupByComponent from '../../components/atom/GroupByComponent';
import ClosedYearAlert from '../../components/atom/ClosedYearAlert';
import ResponsibilitySwitcherTab from '../../components/atom/ResponsibilitySwitcherTab';

const DatalinesPage = ({ responsibility }) => {
    const { user, userPermissions } = useAuth();
    const {
        scope,
        category,
        subcategory,
        emissionSource,
        site,
        year,
        edited,
        setEdited,
        closedYear,
    } = useGlobalFilters();
    const [search, setSearch] = useQueryParam('search', withDefault(StringParam, ''));
    const [groupby, setGroupby] = useQueryParam(
        'groupby',
        withDefault(StringParam, responsibility ? DATALINE_GROUPS.NONE : DATALINE_GROUPS.CATEGORY)
    );
    const [groupOpen, setGroupOpen] = useState([0]);
    const [dataGroups, setDataGroups] = useState([]);
    const { showModal } = useModals();
    const { notifyError } = useNotify();
    const manageDatalinesPermission = userPermissions.includes(PERMISSIONS.MANAGE_DATALINES);

    // create a group props (scope,category,subcategory,emissionSource,site)
    useEffect(() => {
        const baseline = {
            scope,
            category,
            subcategory,
            emissionSource,
            site,
            sectionHeader: '',
        };
        if (groupby === DATALINE_GROUPS.NONE) {
            let sectionHeader = 'Datalines';
            if (responsibility === 'assignedToMe') {
                sectionHeader = 'Datalines assigned to me';
            }
            if (responsibility === 'ownedByMe') {
                sectionHeader = 'Datalines owned by me';
            }
            setDataGroups([
                {
                    id: 0,
                    name: 'All datalines',
                    section: { ...baseline, sectionHeader },
                    type: 'all',
                },
            ]);
            setGroupOpen(['0-all']);
            return;
        }

        if (groupby === DATALINE_GROUPS.SITE) {
            doSwaggerCall('Sites', 'getSitesInCompany', {
                id: user.company_id,
                // TODO: fix meeee
                limit: 1000,
                offset: 0,
            })
                .then((res) => {
                    // filter sites on the group if any of them are selected
                    const filteredSites =
                        site.length === 0
                            ? res.sites
                            : res.sites.filter((e) => site.includes(e.id));
                    if (site.length === 0 || site.includes(0)) {
                        filteredSites.push({ id: 0 });
                    }
                    setDataGroups(
                        filteredSites.map((oneSite) => {
                            const sectionName =
                                oneSite.id === 0 ? 'Corporate' : `Site ${oneSite.name}`;
                            return {
                                id: oneSite.id,
                                name: sectionName,
                                section: {
                                    ...baseline,
                                    site: [oneSite.id],
                                    sectionHeader: sectionName,
                                },
                                type: 'site',
                            };
                        })
                    );
                    // open first 3 sites
                    setGroupOpen(
                        [...filteredSites, { id: 0 }].slice(0, 3).map((e) => `${e.id}-site`)
                    );
                })
                .catch((error) => notifyError(error));
            return;
        }
        if (groupby === DATALINE_GROUPS.CATEGORY) {
            const hScope = scope === null ? undefined : scope;
            doSwaggerCall('Hierarchy', 'getSourceHierarchy', {
                scope: hScope,
                categoryId: category,
                subcategoryId: subcategory,
                responsibility,
                siteIdList: site,
                year,
                hasanydatalines: true,
                hasrequired: !responsibility,
                sourceId: emissionSource,
                hasFactor: true,
            })
                .then((hierarchy) => {
                    const transformResult = hierarchy
                        .map((e) =>
                            transformHierarchyToItem(
                                e,
                                scope,
                                category,
                                subcategory,
                                null, // emissionSource
                                baseline
                            )
                        )
                        // one more sort here since the transformHierarchyToItem only sort children
                        .sort((a, b) =>
                            a.name.localeCompare(b.name, undefined, {
                                numeric: true,
                                sensitivity: 'base',
                            })
                        );
                    setDataGroups(transformResult);
                    if (transformResult.length === 0) {
                        let sectionHeader = 'Datalines';
                        if (responsibility === 'assignedToMe') {
                            sectionHeader = 'Datalines assigned to me';
                        }
                        if (responsibility === 'ownedByMe') {
                            sectionHeader = 'Datalines owned by me';
                        }
                        setDataGroups([
                            {
                                id: 0,
                                name: 'All datalines assigned to me',
                                section: { ...baseline, sectionHeader },
                                type: 'allAssigned',
                            },
                        ]);
                        setGroupOpen(['0-allAssigned']);
                        setGroupby(DATALINE_GROUPS.NONE);
                        // removed this one, since in an empty company, there will be no datalines here
                        // and that is the expected behaviour
                        // notifyInfo('There are no hierarchy found with these filters!');
                    } else {
                        // open scopes
                        const dataGroupOpen = [];
                        transformResult.forEach((dataScope) => {
                            if (dataScope.type === 'scope') {
                                dataGroupOpen.push(`${dataScope.id}-${dataScope.type}`);
                            }
                            // if there is only one source, we open the section
                            if (!transformResult[0].children && transformResult.length === 1) {
                                dataGroupOpen.push(`${dataScope.id}-${dataScope.type}`);
                            }
                        });
                        setGroupOpen(dataGroupOpen);
                    }
                })
                .catch((error) => {
                    notifyError(error);
                });
        }
    }, [
        user,
        groupby,
        scope,
        category,
        subcategory,
        emissionSource,
        site,
        setDataGroups,
        responsibility,
        year,
    ]);

    return (
        <Grid container alignContent="flex-start">
            {closedYear && <ClosedYearAlert />}
            <Grid item xs={12}>
                <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
                    {manageDatalinesPermission && (
                        <Grid item xs={1} style={{ flex: '1 1 0' }}>
                            <Tooltip title="Add new Dataline">
                                <Button
                                    onClick={() => {
                                        showModal(MODAL_TYPE.ADD_DATALINE_WIZARD, {
                                            title: `Add dataline`,
                                            setGroupby,
                                            setGroupOpen,
                                        });
                                    }}
                                    fullWidth
                                    disabled={closedYear}
                                >
                                    <AddIcon />
                                </Button>
                            </Tooltip>
                        </Grid>
                    )}
                    <Grid item xs={manageDatalinesPermission ? 9 : 10} style={{ flex: '1 1 0' }}>
                        <SearchInput
                            fullWidth
                            search={search}
                            setSearch={setSearch}
                            groupby={groupby}
                            setGroupby={setGroupby}
                        />
                    </Grid>
                    <GroupByComponent
                        groupBy={groupby}
                        setGroupBy={setGroupby}
                        search={search}
                        groupByOptions={GROUPBY_OPTIONS}
                    />
                    <ResponsibilitySwitcherTab responsibility={responsibility} type="datalines" />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={0.5}>
                    {dataGroups.map((item, idx) => {
                        return (
                            <DatalineGroup
                                key={idx}
                                groupby={groupby}
                                search={search}
                                toc={[idx + 1]}
                                setGroupOpen={setGroupOpen}
                                responsibility={responsibility}
                                groupOpen={groupOpen}
                                item={item}
                                edited={edited}
                                setEdited={setEdited}
                            />
                        );
                    })}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default DatalinesPage;
