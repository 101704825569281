import { WITHOUT_UNIT } from '../../../helpers/esgUnitConversions';

// get the measure value based on the use value and the measure value
export default function getMeasureValue(field) {
    const measure = field?.measure && field.measure !== WITHOUT_UNIT ? field.measure : '';
    const useValue = field?.use || '';
    const forceOverride = field?.forceOverride || false;
    if (forceOverride && useValue === 'CO2e') {
        return 'CO2e';
    }
    if (forceOverride && useValue === 'Base unit') {
        // use emission source base unit instead of default measure of the field (or backup measure)
        return `${field?.baseUnit || measure}`;
    }
    return `${measure}`;
}
