import {
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
} from '@mui/material';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import GroupIcon from '@mui/icons-material/Group';
import HomeIcon from '@mui/icons-material/Home';
import SwitchLeftIcon from '@mui/icons-material/SwitchLeft';
import SwitchRightIcon from '@mui/icons-material/SwitchRight';
import WarningIcon from '@mui/icons-material/Warning';
import SkeletonTable from '../../../components/atom/SkeletonTable';
import { MODAL_TYPE, useModals } from '../../../hooks/useModal';
import { doSwaggerCall } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';
import { useAuth } from '../../../hooks/useAuth';
import SortableTableCell from '../../../components/atom/SortableTableCell';
import { useGlobalFilters } from '../../../hooks/useGlobalFilters';
import { TABLE_ROW_COUNT_OPTIONS } from '../../../helpers/constans';
import { handleChangeRowsPerPage, handlePageChange } from '../../../helpers/handleTableFunctions';
import { useEsgFilters } from '../../../hooks/useEsgFilters';
import { useEsgFrameworkFilters } from '../../../hooks/useEsgFrameworkFilters';

const CompaniesTable = ({
    rows,
    limit,
    page,
    setParams,
    total,
    loading,
    reloadData,
    sort,
    sortDir,
    setSorting,
}) => {
    const { showModal } = useModals();
    const { notifyError, notifySuccess } = useNotify();
    const { setSwitchIntoCompany, setAccessToken, user } = useAuth();
    const { setSourceFilters } = useGlobalFilters();
    const { setEsgFilters } = useEsgFilters();
    const { setEsgFrameworkFilters } = useEsgFrameworkFilters();

    const deleteCompany = useCallback((id) => {
        doSwaggerCall('Companies', 'deleteCompany', { id })
            .then(() => {
                notifySuccess('Company deleted');
                reloadData();
            })
            .catch(() => {
                notifyError('Cannot delete company');
            });
    }, []);

    const switchCompany = useCallback(
        (id) => {
            doSwaggerCall('Auth', 'switchCompany', {
                companyId: id,
            })
                .then((data) => {
                    const { token, ...otherUserData } = data.user;
                    setSwitchIntoCompany(otherUserData);
                    setAccessToken(token);
                    setSourceFilters({
                        scopeFilter: null,
                        categoryFilter: null,
                        subcategoryFilter: null,
                        emissionSourceFilter: null,
                    });
                    setEsgFilters({
                        esgCategoryFilter: null,
                        esgSubcategoryFilter: null,
                        esgDisclosureFilter: null,
                        esgQuestionFilter: null,
                    });
                    setEsgFrameworkFilters({
                        esgFrameworkFilter: null,
                    });
                    notifySuccess('Switched into company');
                })
                .catch((error) => {
                    notifyError(error);
                });
        },
        [setSwitchIntoCompany, setAccessToken]
    );

    return (
        <Grid item xs={12}>
            <TableContainer component={Paper}>
                <Table aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            <SortableTableCell
                                arrow={sort === 'companyname' ? sortDir : ''}
                                onClick={() => setSorting('companyname')}
                            >
                                Company Name
                            </SortableTableCell>
                            <SortableTableCell
                                width="25%"
                                arrow={sort === 'location' ? sortDir : ''}
                                onClick={() => setSorting('location')}
                            >
                                Location
                            </SortableTableCell>
                            <SortableTableCell
                                width="20%"
                                arrow={sort === 'formula' ? sortDir : ''}
                                onClick={() => setSorting('formula')}
                            >
                                Default formula
                            </SortableTableCell>
                            <TableCell width="25%" />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <SkeletonTable rows={limit} cells={4} />
                        ) : (
                            rows?.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell>
                                        <Grid alignItems="center" sx={{ display: 'flex' }}>
                                            {row.isIncorrect && (
                                                <IconButton
                                                    onClick={() => {
                                                        showModal(
                                                            MODAL_TYPE.INCORRECT_DATALINES_COUNT,
                                                            {
                                                                title: `Incorrect datalines (${row.name})`,
                                                                content: `Totally there are ${row.incorrectDatalinesList
                                                                    .map(
                                                                        (e) =>
                                                                            e.incorrectDatalinesCount
                                                                    )
                                                                    .reduce(
                                                                        (sum, item) => sum + item,
                                                                        0
                                                                    )} incorrect datalines which belongs to ${
                                                                    row.name
                                                                } company:`,
                                                                incorrectList:
                                                                    row.incorrectDatalinesList,
                                                                companyId: row.id,
                                                                warningContent:
                                                                    row.id !== user.company_id,
                                                            }
                                                        );
                                                    }}
                                                >
                                                    <WarningIcon sx={{ color: 'red' }} />
                                                </IconButton>
                                            )}
                                            {row.name}
                                        </Grid>
                                    </TableCell>
                                    <TableCell>{row.location_name}</TableCell>
                                    <TableCell>{row.co2e_logic}</TableCell>
                                    <TableCell align="right">
                                        <Link to={`/companies/${row.id}/users`}>
                                            <Tooltip title="Users">
                                                <IconButton>
                                                    <GroupIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Link>
                                        <Link to={`/companies/${row.id}/sites`}>
                                            <Tooltip title="Sites">
                                                <IconButton>
                                                    <HomeIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Link to={`/companies/edit/${row.id}/basic`}>
                                                <Tooltip title="Edit">
                                                    <IconButton>
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Link>
                                        </Link>
                                        <Tooltip title="Switch to company">
                                            <IconButton
                                                disabled={user?.company_id === row.id}
                                                onClick={() => {
                                                    switchCompany(row.id);
                                                }}
                                            >
                                                {user?.company_id === row.id ? (
                                                    <SwitchRightIcon />
                                                ) : (
                                                    <SwitchLeftIcon disabled />
                                                )}
                                            </IconButton>
                                        </Tooltip>
                                        <IconButton
                                            onClick={() => {
                                                showModal(MODAL_TYPE.CONFIRMATION, {
                                                    title: `Are you sure you want to remove this company?`,
                                                    content: 'This action is irreversible.',
                                                    confirm: () => deleteCompany(row.id),
                                                });
                                            }}
                                        >
                                            <Tooltip title="Delete">
                                                <DeleteIcon />
                                            </Tooltip>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                count={total || 0}
                rowsPerPage={limit}
                component="div"
                page={page}
                onPageChange={(_, newPage) => handlePageChange(newPage, setParams, limit)}
                rowsPerPageOptions={TABLE_ROW_COUNT_OPTIONS}
                onRowsPerPageChange={(event) => handleChangeRowsPerPage(event, setParams)}
            />
        </Grid>
    );
};

export default CompaniesTable;
