import React from 'react';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PieChartIcon from '@mui/icons-material/PieChart';
import BarChartIcon from '@mui/icons-material/BarChart';
import SsidChartIcon from '@mui/icons-material/SsidChart';

export const CHART_GROUPS = Object.freeze({
    all: 'All',
    pie: 'Pie chart',
    bar: 'Bar chart',
    line: 'Line chart',
    ghg: 'GHG',
    esg: 'ESG',
    scope: 'Scope',
    category: 'Category',
    subcategory: 'Subcategory',
    site: 'Site',
    source: 'Source',
    supplier: 'Supplier',
    intensity: 'Intensity metric',
    planning: 'Planning',
    tag: 'Tag',
});

export const DASHBOARD_VISUALIZATION_TYPES = Object.freeze({
    PIE: 'pie', // piechart for each category
    BAR: 'bar', // simple barchart for categories
    // RADIAL_BAR: 'radial_bar', // radial bar for data completion
    TOTAL_EMISSIONS: 'total_emissions', // total emission for each scope
    SCOPE_LINE_CHART: 'scope_line_chart', // line chart per years for scopes
    CATEGORY_BAR_CHART: 'category_bar_chart', // bar chart grouped by years for selected categories
    TAG_BAR_CHART: 'tag_bar_chart', // bar chart grouped by years for selected tags
    CATEGORY_PIE_CHART: 'category_pie_chart', // pie chart by one year for selected category
    SCOPE_PIE_CHART: 'scope_pie_chart', // pie chart by one year for selected scope
    SUBCATEGORY_PIE_CHART: 'subcategory_pie_chart', // pie chart by one year for selected subcategory
    SITE_PIE_CHART: 'site_pie_chart', // pie chart by one year for selected site
    SITE_BAR_CHART: 'site_bar_chart', // bar chart for sites by selected categories, scopes and year
    SCENARIO_LINE_CHART: 'scenario_line_chart', // line chart for scenarios with actual emissions
    SOURCE_BAR_CHART: 'source_bar_chart', // bar chart for source grouped by selected years, displaying amounts
    SUPPLIER_BAR_CHART: 'supplier_bar_chart', // bar chart for suppliers grouped by selected years, displaying co2e (mt)
    SCOPES_PER_INTENSITY_LINE_CHART: 'scopes_per_intensity_line_chart', // line chart for scopes per intensity metrics
    SUPPLIER_NETZERO_TARGETS_BAR_CHART: 'supplier_netzero_targets_bar_chart', // bar chart for suppliers grouped by selected year, displaying netzero targets
    ESG_QUESTION_BAR_CHART: 'esg_question_bar_chart', // bar chart for esg questions grouped by selected years and sites
    SITES_PER_INTENSITY_BAR_CHART: 'sites_per_intensity_bar_chart', // bar chart for sites per intensity metrics over years
    SOURCE_PER_DATES_LINE_CHART: 'source_per_dates_line_chart', // line chart for source per dates
    ESG_SCENARIO_LINE_CHART: 'esg_scenario_line_chart', // line chart for esg scenario
    ESG_QUESTION_LINE_CHART: 'esg_question_line_chart', // line chart for esg questions grouped by selected years and sites
});

export const PERMISSIONS = Object.freeze({
    VIEW_DASHBOARD: 'view_dashboard',
    VIEW_DATALINES: 'view_datalines',
    VIEW_ASSIGNED_TO: 'view_assigned_to',
    VIEW_OWNED: 'view_owned',
    VIEW_REQUIREMENTS: 'view_requirements',
    BASIC_MODIFY_DATALINES: 'basic_modify_datalines',
    COMPLEX_MODIFY_DATALINES: 'complex_modify_datalines',
    MANAGE_DATALINES: 'manage_datalines',
    MANAGE_SCENARIOS: 'manage_scenarios',
    MANAGE_COMPANY: 'manage_company',
    MANAGE_SUPPLIERS: 'manage_suppliers',
    MANAGE_ESGS: 'manage_esgs',
});

export const PERMISSION_LABELS = Object.freeze({
    [PERMISSIONS.VIEW_DASHBOARD]: 'View dashboard',
    [PERMISSIONS.VIEW_DATALINES]: 'View all datalines',
    [PERMISSIONS.VIEW_ASSIGNED_TO]: 'View assigned to me datalines',
    [PERMISSIONS.VIEW_OWNED]: 'View owned by me datalines',
    [PERMISSIONS.VIEW_REQUIREMENTS]: 'View progress tracker',
    [PERMISSIONS.BASIC_MODIFY_DATALINES]: 'Basic modify datalines',
    [PERMISSIONS.COMPLEX_MODIFY_DATALINES]: 'Complex modify datalines',
    [PERMISSIONS.MANAGE_DATALINES]: 'Manage datalines',
    [PERMISSIONS.MANAGE_SCENARIOS]: 'Manage scenarios',
    [PERMISSIONS.MANAGE_COMPANY]: 'Manage company',
    [PERMISSIONS.MANAGE_SUPPLIERS]: 'Manage suppliers',
    [PERMISSIONS.MANAGE_ESGS]: 'Manage ESGs',
});

export const PERMISSION_SET_NAMES = Object.freeze({
    NORMAL_USER: 'Basic user',
    APPROVER: 'Approver',
    EXECUTIVE: 'Executive',
    ADMINISTRATOR: 'Administrator',
    CUSTOM: 'Custom',
});

export const PERMISSION_SETS = Object.freeze({
    [PERMISSION_SET_NAMES.NORMAL_USER]: [
        PERMISSIONS.VIEW_ASSIGNED_TO,
        PERMISSIONS.BASIC_MODIFY_DATALINES,
    ],
    [PERMISSION_SET_NAMES.APPROVER]: [
        PERMISSIONS.VIEW_DASHBOARD,
        PERMISSIONS.VIEW_ASSIGNED_TO,
        PERMISSIONS.VIEW_OWNED,
        PERMISSIONS.BASIC_MODIFY_DATALINES,
        PERMISSIONS.COMPLEX_MODIFY_DATALINES,
    ],
    [PERMISSION_SET_NAMES.ADMINISTRATOR]: [
        PERMISSIONS.VIEW_DASHBOARD,
        PERMISSIONS.VIEW_DATALINES,
        PERMISSIONS.VIEW_ASSIGNED_TO,
        PERMISSIONS.VIEW_OWNED,
        PERMISSIONS.VIEW_REQUIREMENTS,
        PERMISSIONS.BASIC_MODIFY_DATALINES,
        PERMISSIONS.COMPLEX_MODIFY_DATALINES,
        PERMISSIONS.MANAGE_DATALINES,
        PERMISSIONS.MANAGE_SCENARIOS,
        PERMISSIONS.MANAGE_COMPANY,
        PERMISSIONS.MANAGE_SUPPLIERS,
        PERMISSIONS.MANAGE_ESGS,
    ],
    [PERMISSION_SET_NAMES.EXECUTIVE]: [
        PERMISSIONS.VIEW_DASHBOARD,
        PERMISSIONS.VIEW_DATALINES,
        PERMISSIONS.VIEW_REQUIREMENTS,
        PERMISSIONS.MANAGE_SCENARIOS,
    ],
    [PERMISSION_SET_NAMES.CUSTOM]: [PERMISSIONS.VIEW_DASHBOARD],
});

export const ROLES = Object.freeze({
    USER: 'user',
    SUPERADMIN: 'superadmin',
});

// default descriptions for charts by visualization types
export const DASHBOARD_DEFAULT_DESCRIPTIONS = Object.freeze({
    pie: 'Pie chart for subcategory breakdown',
    bar: 'Bar chart for categories',
    // radial_bar: 'Chart for data completion',
    total_emissions: 'Total emission for each scope',
    scope_line_chart: 'Line chart per years for scopes',
    category_bar_chart: 'Bar chart grouped by years for selected categories',
    tag_bar_chart: 'Bar chart grouped by years for selected tags',
    category_pie_chart: 'Pie chart by one year for selected category',
    subcategory_pie_chart: 'Pie chart by one year for selected subcategory',
    scope_pie_chart: 'Pie chart by one year for selected scope',
    site_pie_chart: 'Pie chart by one year for selected site',
    site_bar_chart: 'Bar chart for sites by selected categories, scopes and year',
    scenario_line_chart: 'Line chart for scenarios with actual emissions',
    source_bar_chart: 'Bar chart grouped by years for selected source (displaying base unit)',
    supplier_bar_chart: 'Bar chart for suppliers grouped by selected years, displaying CO2e (mt)',
    scopes_per_intensity_line_chart:
        'Line chart for scopes divided by intensity metric for selected years',
    supplier_netzero_targets_bar_chart:
        'Bar chart for suppliers grouped by selected year, displaying netzero targets',
    esg_question_bar_chart:
        'Bar chart displaying ESG question metrics for numeric fields, grouped by selected years and sites.',
    sites_per_intensity_bar_chart:
        'Bar chart for selected sites per intensity metric over years in a predefined scope or category.',
    esg_scenario_line_chart: 'Line chart for ESG scenario',
});

// How many rows to show per page by default
export const TABLE_ROW_COUNT_DEFAULT = 25;
// Row count options for tables
export const TABLE_ROW_COUNT_OPTIONS = [25, 50, 100];

// Supplier survey types for step 3
export const SUPPLIER_SURVEY_SCOPE3_REPORT_TYPE = Object.freeze({
    not_applicable: 'Not applicable',
    we_have_not_calculated_it: 'We have not calculated it',
    have_it_in_the_report: 'Have it in the report',
});

// Audit log descriptions
export const AUDIT_LOG_DESCRIPTIONS = Object.freeze({
    login: 'User has logged in.',
    reset_default_password: 'User password reset request when admin create user.',
    reset_password: 'User password reseted.',
    reset_password_request: 'User password reset request.',
    switch_company: 'User has switched company.',
    add_category: 'A category has been added.',
    delete_category: 'A category has been deleted.',
    edit_category: 'A category has been edited.',
    add_company: 'A company has been added.',
    delete_company: 'A company has been deleted.',
    update_company: 'A company has been updated.',
    add_company_tag: 'A company tag has been added.',
    delete_company_tag: 'A company tag has been deleted.',
    edit_company_tag: 'A company tag has been edited.',
    contact_us: 'User has contacted us.',
    add_conversion_template: 'A conversion template has been added.',
    delete_conversion_template: 'A conversion template has been deleted.',
    edit_conversion_template: 'A conversion template has been edited.',
    add_dashboard: 'A dashboard has been added.',
    delete_dashboard: 'A dashboard has been deleted.',
    edit_dashboard: 'A dashboard has been edited.',
    add_dataline: 'A dataline has been added.',
    assign_datalines_by_list: 'Datalines have been assigned by list.',
    delete_datalines_by_list: 'Datalines have been deleted by list.',
    edit_dataline: 'A dataline has been edited.',
    import_datalines: 'Datalines have been imported.',
    add_emission_source: 'An emission source has been added.',
    edit_emission_source: 'An emission source has been edited.',
    edit_emission_source_conversion_logic: 'Emission source conversion logic has been edited.',
    delete_emission_source: 'An emission source has been deleted.',
    update_emission_factor: 'An emission factor has been updated.',
    add_emission_factor: 'An emission factor has been added.',
    copy_emission_factors: 'Emission factors have been copied.',
    delete_emission_factor: 'An emission factor has been deleted.',
    import_emission_factors: 'Emission factors have been imported.',
    documents_request: 'A document request has been sent.',
    upload_document: 'A document has been uploaded.',
    upload_document_multiple: 'Multiple documents have been uploaded.',
    upload_file: 'A file has been uploaded.',
    save_intensity_metrics: 'Intensity metrics have been saved.',
    delete_intensity_metric: 'An intensity metric has been deleted.',
    add_location: 'A location has been added.',
    delete_location: 'A location has been deleted.',
    update_location: 'A location has been updated.',
    import_locations: 'Locations have been imported.',
    edit_notifications_by_list: 'Notifications have been edited by list.',
    audit_report_request: 'Audit report request.',
    get_audit_report: 'Audit report has been generated.',
    get_scenario_report: 'Scenario report has been generated.',
    get_xlsx_audit_report: 'XLSX audit report has been generated.',
    scenario_report_request: 'Scenario report request.',
    copy_requirements: 'Requirements have been copied.',
    add_scenario: 'A scenario has been added.',
    delete_scenario: 'A scenario has been deleted.',
    edit_scenario: 'A scenario has been edited.',
    edit_scenario_hierarchy: 'A scenario hierarchy has been edited.',
    get_scenario_suggestion: 'Scenario suggestion have been generated by the AI.',
    add_site: 'A site has been added.',
    delete_site: 'A site has been deleted.',
    update_site: 'A site has been updated.',
    import_sites: 'Sites have been imported.',
    add_subcategory: 'A subcategory has been added.',
    delete_subcategory: 'A subcategory has been deleted.',
    edit_subcategory: 'A subcategory has been edited.',
    subscribe_to_newsletter: 'User has subscribed to newsletter.',
    add_supplier: 'A supplier has been added.',
    delete_suppliers_by_list: 'Suppliers have been deleted by list.',
    edit_supplier: 'A supplier has been edited.',
    import_suppliers: 'Suppliers have been imported.',
    add_survey: 'A survey has been added.',
    add_survey_fourth_step: 'A survey has been added (fourth step).',
    send_survey: 'A survey has been sent.',
    add_user: 'A user has been added.',
    delete_user_from_company: 'A user has been deleted from company.',
    edit_me: 'User has edited his profile.',
    edit_user: 'A user has been edited.',
    import_users: 'Users have been imported.',
    get_datalines: 'Datalines have been fetched.',
    get_emission_source: 'Emission source has been fetched.',
    get_sites_in_company: 'Sites in company have been fetched.',
    get_suppliers: 'Suppliers have been fetched.',
    get_users_in_company: 'Users in company have been fetched.',
    approve_and_close_survey: 'Survey has been approved and closed.',
    reopen_survey: 'Survey has been reopened.',
    add_survey_note: 'Survey note has been added.',
    save_company_years: 'Company years have been saved.',
    copy_datalines: 'Datalines have been copied.',
    save_two_factor_auth: 'Two factor authentication has been saved.',
    reset_two_factor_auth: 'Two factor authentication has been reset.',
    remove_emission_source_conversion_template:
        'Emission source conversion template connection has been removed.',
    add_esg: 'An ESG has been added.',
    delete_esgs_by_list: 'ESGs have been deleted by list.',
    edit_esg: 'An ESG has been edited.',
    upload_esg_document: 'An ESG document has been uploaded.',
    upload_esg_document_multiple: 'Multiple ESG documents have been uploaded.',
    esg_report_request: 'ESG report request.',
    get_esg_report: 'ESG report has been generated.',
    get_xlsx_esg_report: 'XLSX ESG report has been generated.',
    assign_esgs_by_list: 'ESGs have been assigned by list.',
    edit_bulk_notes: 'Datalines notes has been edited.',
    edit_bulk_estimated: 'Datalines estimated value has been edited.',
    edit_bulk_tags: 'Datalines tags has been edited.',
    add_esg_question: 'An ESG question has been added.',
    delete_esg_question: 'An ESG question has been deleted.',
    edit_esg_question: 'An ESG question has been edited.',
    import_esg_questions: 'ESG questions have been imported.',
    add_esg_disclosure: 'An ESG disclosure has been added.',
    delete_esg_disclosure: 'An ESG disclosure has been deleted.',
    edit_esg_disclosure: 'An ESG disclosure has been edited.',
    add_esg_subcategory: 'An ESG subcategory has been added.',
    edit_esg_subcategory: 'An ESG subcategory has been edited.',
    delete_esg_subcategory: 'An ESG subcategory has been deleted.',
    add_esg_category: 'An ESG category has been added.',
    edit_esg_category: 'An ESG category has been edited.',
    delete_esg_category: 'An ESG category has been deleted.',
    add_esg_framework: 'An ESG framework has been added.',
    edit_esg_framework: 'An ESG framework has been edited.',
    delete_esg_framework: 'An ESG framework has been deleted.',
    add_esg_framework_question: 'An ESG framework question has been added.',
    delete_esg_framework_question: 'An ESG framework question has been deleted.',
    edit_esg_framework_question: 'An ESG framework question has been edited.',
    edit_imp_form: 'An imp form has been edited.',
    clear_imp_form: 'An imp form has been cleared.',
    upload_imp_document: 'An imp document has been uploaded.',
    copy_imp_form: 'An imp form has been copied.',
    attach_documents: 'Documents have been attached.',
    imp_report_request: 'Inventory management plan report request.',
    get_imp_report: 'Inventory management plan report has been generated.',
    delete_company_year: 'A company year has been deleted.',
    request_account_deletion: 'User has requested account deletion.',
    audit_company_year: 'Company year has been audited.',
    upload_audit_document: 'An audit document has been uploaded.',
    copy_esg_requirements: 'ESG requirements have been copied.',
    import_esgs: 'ESGs have been imported.',
    copy_esgs: 'ESGs have been copied.',
    resend_email: 'Email has been sent again.',
    add_esg_scenario: 'An ESG scenario has been added.',
    edit_esg_scenario: 'An ESG scenario has been edited.',
    delete_esg_scenario: 'An ESG scenario has been deleted.',
    esg_scenario_report_request: 'ESG scenario report request.',
    get_esg_scenario_report: 'ESG scenario report has been generated.',
    get_esg_suggestion: 'ESG suggestion have been generated by the AI.',
    copy_intensity_metrics: 'Intensity metrics have been copied.',
});

// User notification settings
export const NOTIFICATIONS = Object.freeze({
    DATALINE_ASSIGNED: 'dataline_assigned',
    DATALINE_OWNED: 'dataline_owned',
    DATALINE_ASSIGNED_REMOVE: 'dataline_assigned_remove',
    DATALINE_OWNED_REMOVE: 'dataline_owned_remove',
    DATALINE_EDITED: 'dataline_edited',
    DATALINE_DELETED: 'dataline_deleted',
    ESG_ASSIGNED: 'esg_assigned',
    ESG_OWNED: 'esg_owned',
    ESG_ASSIGNED_REMOVE: 'esg_assigned_remove',
    ESG_OWNED_REMOVE: 'esg_owned_remove',
    ESG_EDITED: 'esg_edited',
    ESG_DELETED: 'esg_deleted',
});

export const NOTIFICATION_LABELS = Object.freeze({
    [NOTIFICATIONS.DATALINE_ASSIGNED]: 'Dataline assigned to you',
    [NOTIFICATIONS.DATALINE_OWNED]: 'Dataline owned by you',
    [NOTIFICATIONS.DATALINE_ASSIGNED_REMOVE]: 'Removed from dataline (assigned)',
    [NOTIFICATIONS.DATALINE_OWNED_REMOVE]: 'Removed from dataline (owned)',
    [NOTIFICATIONS.DATALINE_EDITED]: 'Dataline edited that is owned by you',
    [NOTIFICATIONS.DATALINE_DELETED]: 'Dataline deleted that is owned by you',
});

// Available options for table select dropdowns
export const AVAILABLE_OPTIONS_FOR_DATALINES = [
    // upload documents part of the menu is handled in the component
    { value: 'setAssignee', label: 'Change Assignee' },
    { value: 'setOwner', label: 'Change Owner' },
    { value: 'setEstimated', label: 'Set Estimated' },
    { value: 'addNote', label: 'Add Note' },
    { value: 'assignTags', label: 'Assign Tags' },
    { value: 'deleteDatalines', label: 'Delete selected datalines' },
];

export const AVAILABLE_OPTIONS_FOR_SUPPLIERS = [
    { value: 'copySuppliers', label: 'Copy selected suppliers' },
    { value: 'sendSurvey', label: 'Send surveys to suppliers' },
    { value: 'deleteSuppliers', label: 'Delete selected suppliers' },
];

export const AVAILABLE_OPTIONS_FOR_ESGS = [
    // upload documents part of the menu is handled in the component
    { value: 'setAssignee', label: 'Change Assignee' },
    { value: 'setOwner', label: 'Change Owner' },
    { value: 'setEstimated', label: 'Set Estimated' },
    { value: 'addNote', label: 'Add Note' },
    { value: 'assignTags', label: 'Assign Tags' },
    { value: 'deleteEsgs', label: 'Delete selected ESGs' },
];

export const DASHBOARD_MENU_ITEMS = [
    {
        value: 'total_emissions',
        label: 'Total Emissions per Scopes',
        description: 'This chart shows the total emissions for each scope.',
        icon: <ListAltIcon fontSize="small" />,
        group: [CHART_GROUPS.ghg],
    },
    // {
    //     value: 'radial_bar',
    //     label: 'Data completion',
    //     icon: <DataSaverOffIcon fontSize="small" />,
    // },
    {
        value: 'pie',
        label: 'Subcategory Breakdown',
        description: 'This chart shows a category breakdown using pie charts.',
        icon: <PieChartIcon fontSize="small" />,
        group: [CHART_GROUPS.pie, CHART_GROUPS.ghg, CHART_GROUPS.subcategory],
    },
    {
        value: 'bar',
        label: 'All Categories',
        description: 'This is a bar chart for categories.',
        icon: <BarChartIcon fontSize="small" />,
        group: [CHART_GROUPS.bar, CHART_GROUPS.ghg, CHART_GROUPS.category],
    },
    {
        value: 'scope_pie_chart',
        label: 'Scope',
        description: 'This is a pie chart for the selected scope in the selected year.',
        icon: <PieChartIcon fontSize="small" />,
        group: [CHART_GROUPS.pie, CHART_GROUPS.ghg, CHART_GROUPS.scope],
    },
    {
        value: 'category_pie_chart',
        label: 'Category',
        description: 'This is a pie chart for the selected category in the selected year.',
        icon: <PieChartIcon fontSize="small" />,
        group: [CHART_GROUPS.pie, CHART_GROUPS.ghg, CHART_GROUPS.category],
    },
    {
        value: 'subcategory_pie_chart',
        label: 'Subcategory',
        description: 'This is a pie chart for the selected subcategory in the selected year.',
        icon: <PieChartIcon fontSize="small" />,
        group: [CHART_GROUPS.pie, CHART_GROUPS.ghg, CHART_GROUPS.subcategory],
    },
    {
        value: 'site_pie_chart',
        label: 'Site',
        description: 'This pie chart shows data by year for the selected site.',
        icon: <PieChartIcon fontSize="small" />,
        group: [CHART_GROUPS.pie, CHART_GROUPS.ghg, CHART_GROUPS.site],
    },
    {
        value: 'category_bar_chart',
        label: 'Categories',
        description: 'This is a bar chart for the selected categories in the selected years.',
        icon: <BarChartIcon fontSize="small" />,
        group: [CHART_GROUPS.bar, CHART_GROUPS.ghg, CHART_GROUPS.category],
    },
    {
        value: 'tag_bar_chart',
        label: 'Tags',
        description: 'This is a bar chart grouped by years for the selected tags.',
        icon: <BarChartIcon fontSize="small" />,
        group: [CHART_GROUPS.bar, CHART_GROUPS.ghg, CHART_GROUPS.tag],
    },
    {
        value: 'site_bar_chart',
        label: 'Sites',
        description: 'This is a bar chart for the selected sites in the selected year.',
        icon: <BarChartIcon fontSize="small" />,
        group: [CHART_GROUPS.bar, CHART_GROUPS.ghg, CHART_GROUPS.site],
    },
    {
        value: 'source_bar_chart',
        label: 'Source',
        description:
            'This is a bar chart grouped by years for the selected source displaying base unit.',
        icon: <BarChartIcon fontSize="small" />,
        group: [CHART_GROUPS.bar, CHART_GROUPS.ghg, CHART_GROUPS.source],
    },
    {
        value: 'supplier_bar_chart',
        label: 'Suppliers',
        description:
            'This is a bar chart for suppliers grouped by selected years, displaying CO2e (mt).',
        icon: <BarChartIcon fontSize="small" />,
        group: [CHART_GROUPS.bar, CHART_GROUPS.ghg, CHART_GROUPS.supplier],
    },
    {
        value: 'supplier_netzero_targets_bar_chart',
        label: 'Suppliers Net Zero Targets',
        description:
            'This is a bar chart for suppliers grouped by selected year, displaying net zero targets.',
        icon: <BarChartIcon fontSize="small" />,
        group: [CHART_GROUPS.bar, CHART_GROUPS.ghg, CHART_GROUPS.supplier],
    },
    {
        value: 'esg_question_bar_chart',
        label: 'ESG Metric',
        description:
            'This is a bar chart for numeric ESG questions grouped by selected years and sites.',
        icon: <BarChartIcon fontSize="small" />,
        group: [CHART_GROUPS.bar, CHART_GROUPS.esg],
    },
    {
        value: 'sites_per_intensity_bar_chart',
        label: 'Intensity Metric per Sites',
        description:
            'This is a bar chart for selected sites per intensity metric over years in a predefined scope or category.',
        icon: <BarChartIcon fontSize="small" />,
        group: [CHART_GROUPS.bar, CHART_GROUPS.intensity, CHART_GROUPS.site],
    },
    {
        value: 'scope_line_chart',
        label: 'Scopes',
        description: 'This is a line chart for the selected scopes per years.',
        icon: <SsidChartIcon fontSize="small" />,
        group: [CHART_GROUPS.line, CHART_GROUPS.ghg, CHART_GROUPS.scope],
    },
    {
        value: 'scenario_line_chart',
        label: 'Reduction Targets',
        description: 'This is a line chart for the selected scenarios with the actual emissions.',
        icon: <SsidChartIcon fontSize="small" />,
        group: [CHART_GROUPS.line, CHART_GROUPS.ghg, CHART_GROUPS.planning],
    },
    {
        value: 'scopes_per_intensity_line_chart',
        label: 'Intensity Metrics',
        description:
            'This is a line chart for scopes divided by intensity metric for selected years.',
        icon: <SsidChartIcon fontSize="small" />,
        group: [CHART_GROUPS.line, CHART_GROUPS.intensity, CHART_GROUPS.scope],
    },
    {
        value: 'source_per_dates_line_chart',
        label: 'Source per Dates',
        description: 'This line chart displays data trends over time, illustrating the source distribution.',
        icon: <SsidChartIcon fontSize="small" />,
        group: [CHART_GROUPS.line, CHART_GROUPS.ghg, CHART_GROUPS.source],
    },
    {
        value: 'esg_scenario_line_chart',
        label: 'ESG Targets and Plannings',
        description:
            'This is a line chart for the selected ESG scenario including the selected question.',
        icon: <SsidChartIcon fontSize="small" />,
        group: [CHART_GROUPS.line, CHART_GROUPS.esg, CHART_GROUPS.planning],
    },
    {
        value: 'esg_question_line_chart',
        label: 'ESG Metric line chart',
        description:
            'This is a line chart for numeric ESG questions grouped by selected years and sites.',
        icon: <SsidChartIcon fontSize="small" />,
        group: [CHART_GROUPS.line, CHART_GROUPS.esg],
    },
];

export const WARNING_CONTENT_RECALCULATE_TEXT =
    'This action may take a few moments to recalculate emissions data in the background. Thank you for your patience.';
