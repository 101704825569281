import numericSort from './helpers/numericSort';
import { doSwaggerCall } from './hooks/useApi';

export const subcategoryAutocomplete = {};
subcategoryAutocomplete.init = (ids) => {
    if (ids.length === 0 || (ids.length === 1 && ids[0] === '')) {
        return [];
    }
    return doSwaggerCall('Subcategories', 'getAllSubcategories', {
        pageSize: ids.length,
        idList: ids,
        sourceLimitation: true,
    }).then(({ subcategories }) =>
        numericSort(subcategories).map((e) => {
            return {
                category_id: e.category_id,
                category_name: e.category_name,
                id: e.id,
                name: e.name,
                scope_id: e.scope_id,
                scope_name: e.scope_name,
            };
        })
    );
};

export const subcategoryWithoutLimitationAutocomplete = {};
subcategoryWithoutLimitationAutocomplete.init = (ids) => {
    if (ids.length === 0 || (ids.length === 1 && ids[0] === '')) {
        return [];
    }
    return doSwaggerCall('Subcategories', 'getAllSubcategories', {
        pageSize: ids.length,
        idList: ids,
    }).then(({ subcategories }) =>
        numericSort(subcategories).map((e) => {
            return {
                category_id: e.category_id,
                category_name: e.category_name,
                id: e.id,
                name: e.name,
                scope_id: e.scope_id,
                scope_name: e.scope_name,
            };
        })
    );
};

subcategoryAutocomplete.search = (search, pageSize, { category, scope, sourceLimitation }) => {
    return doSwaggerCall('Subcategories', 'getAllSubcategories', {
        pageSize,
        search,
        categoryId: category,
        scope,
        sourceLimitation,
    }).then(({ subcategories }) =>
        numericSort(subcategories).map((e) => {
            return {
                category_id: e.category_id,
                category_name: e.category_name,
                id: e.id,
                name: e.name,
                scope_id: e.scope_id,
                scope_name: e.scope_name,
            };
        })
    );
};

export const subcategoryWithCategoryAutocomplete = {};
subcategoryWithCategoryAutocomplete.init = (ids) => {
    if (ids.length === 0 || (ids.length === 1 && ids[0] === '')) {
        return [];
    }
    return doSwaggerCall('Subcategories', 'getAllSubcategories', {
        pageSize: ids.length,
        idList: ids,
    }).then(({ subcategories }) =>
        numericSort(subcategories).map((e) => {
            return { id: e.id, name: `${e.category_name} > ${e.name}` };
        })
    );
};
subcategoryWithCategoryAutocomplete.search = (search, pageSize) => {
    return doSwaggerCall('Subcategories', 'getAllSubcategories', {
        pageSize,
        search,
    }).then(({ subcategories }) =>
        numericSort(subcategories).map((e) => {
            return { id: e.id, name: `${e.category_name} > ${e.name}` };
        })
    );
};

export const sourceAutocomplete = {};
sourceAutocomplete.init = (ids, searchParams) => {
    if (ids.length === 0 || (ids.length === 1 && ids[0] === '')) {
        return [];
    }
    return doSwaggerCall('EmissionSources', 'getEmissionSources', {
        limit: ids.length, // pageSize
        idList: ids,
        hasFactorYear: searchParams.year,
        sourceLimitation: true,
    }).then(({ emissionSources }) =>
        numericSort(emissionSources).map((e) => {
            return {
                id: e.id,
                name: e.name,
                subcategory_id: e.subcategory_id,
                subcategory_name: e.subcategory_name,
                category_id: e.category_id,
                category_name: e.category_name,
                scope_id: e.scope_id,
                scope_name: e.scope_name,
            };
        })
    );
};

export const sourceWithoutLimitationAutocomplete = {};
sourceWithoutLimitationAutocomplete.init = (ids, searchParams) => {
    if (ids.length === 0 || (ids.length === 1 && ids[0] === '')) {
        return [];
    }
    return doSwaggerCall('EmissionSources', 'getEmissionSources', {
        limit: ids.length, // pageSize
        idList: ids,
        hasFactorYear: searchParams.year,
    }).then(({ emissionSources }) =>
        numericSort(emissionSources).map((e) => {
            return {
                id: e.id,
                name: e.name,
                subcategory_id: e.subcategory_id,
                subcategory_name: e.subcategory_name,
                category_id: e.category_id,
                category_name: e.category_name,
                scope_id: e.scope_id,
                scope_name: e.scope_name,
            };
        })
    );
};
sourceAutocomplete.search = (
    search,
    pageSize,
    { subcategory, category, scope, year, sourceLimitation }
) => {
    return doSwaggerCall('EmissionSources', 'getEmissionSources', {
        limit: pageSize,
        search,
        subcategoryId: subcategory,
        categoryId: category,
        scope,
        hasFactorYear: year,
        sourceLimitation,
    }).then(({ emissionSources }) =>
        numericSort(emissionSources).map((e) => {
            return {
                id: e.id,
                name: e.name,
                subcategory_id: e.subcategory_id,
                subcategory_name: e.subcategory_name,
                category_id: e.category_id,
                category_name: e.category_name,
                scope_id: e.scope_id,
                scope_name: e.scope_name,
            };
        })
    );
};

export const locationAutocomplete = {};
locationAutocomplete.init = (ids) => {
    if (ids.length === 1 && ids[0] === '') {
        return [];
    }
    return doSwaggerCall('Locations', 'getAllLocations', {
        pageSize: ids.length,
        idList: ids,
    }).then(({ locations }) =>
        locations
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((e) => {
                return { id: e.id, name: e.name };
            })
    );
};
locationAutocomplete.search = (search, pageSize) => {
    return doSwaggerCall('Locations', 'getAllLocations', {
        pageSize,
        search,
    }).then(({ locations }) =>
        locations
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((e) => {
                return { id: e.id, name: e.name };
            })
    );
};

export const categoryAutocomplete = {};
categoryAutocomplete.init = (ids) => {
    if (ids.length === 0 || (ids.length === 1 && ids[0] === '')) {
        return [];
    }
    return doSwaggerCall('Categories', 'getAllCategories', {
        pageSize: ids.length,
        idList: ids,
        sourceLimitation: true,
    }).then(({ categories }) =>
        numericSort(categories).map((e) => {
            return { id: e.id, name: e.name, scope_id: e.scope_id, scope_name: e.scope_name };
        })
    );
};
export const categoryWithoutLimitationsAutocomplete = {};
categoryWithoutLimitationsAutocomplete.init = (ids) => {
    if (ids.length === 0 || (ids.length === 1 && ids[0] === '')) {
        return [];
    }
    return doSwaggerCall('Categories', 'getAllCategories', {
        pageSize: ids.length,
        idList: ids,
    }).then(({ categories }) =>
        numericSort(categories).map((e) => {
            return { id: e.id, name: e.name, scope_id: e.scope_id, scope_name: e.scope_name };
        })
    );
};
categoryAutocomplete.search = (search, pageSize, { scope, sourceLimitation }) => {
    return doSwaggerCall('Categories', 'getAllCategories', {
        pageSize,
        search,
        scope,
        sourceLimitation,
    }).then(({ categories }) =>
        numericSort(categories).map((e) => {
            return { id: e.id, name: e.name, scope_id: e.scope_id, scope_name: e.scope_name };
        })
    );
};

export const categoryWithoutLimitationForSupplierAutocomplete = {};
categoryWithoutLimitationForSupplierAutocomplete.init = (ids) => {
    if (ids.length === 0 || (ids.length === 1 && ids[0] === '')) {
        return [];
    }
    return doSwaggerCall('Categories', 'getAllCategories', {
        pageSize: ids.length,
        idList: ids,
    }).then(({ categories }) =>
        numericSort(categories).map((e) => {
            return {
                id: e.id,
                category_name: e.name,
                scope_name: e.scope_name,
                name: `${e?.scope_name} - ${e?.name}`,
            };
        })
    );
};
categoryWithoutLimitationForSupplierAutocomplete.search = (search, pageSize) => {
    return doSwaggerCall('Categories', 'getAllCategories', {
        pageSize,
        search,
    }).then(({ categories }) =>
        numericSort(categories).map((e) => {
            return {
                id: e.id,
                category_name: e.name,
                scope_name: e.scope_name,
                name: `${e?.scope_name} - ${e?.name}`,
            };
        })
    );
};

export const userAutocomplete = {};
userAutocomplete.init = (ids) => {
    if (ids.length === 0 || (ids.length === 1 && ids[0] === '')) {
        return [];
    }
    return doSwaggerCall('Users', 'getAllUsers', {
        pageSize: ids.length,
        idList: ids,
    }).then(({ users }) =>
        users
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((e) => {
                return { id: e.id, name: e.name, profile_picture: e.profile_picture };
            })
    );
};
userAutocomplete.search = (search, pageSize) => {
    return doSwaggerCall('Users', 'getAllUsers', {
        pageSize,
        search,
    }).then(({ users }) =>
        users
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((e) => {
                return { id: e.id, name: e.name, profile_picture: e.profile_picture };
            })
    );
};

export const siteAutocomplete = {};
siteAutocomplete.init = (ids) => {
    if (ids.length === 0 || (ids.length === 1 && ids[0] === '')) {
        return [];
    }
    return doSwaggerCall('Sites', 'getSitesForUsers', {
        pageSize: ids.length,
        idList: ids,
    }).then(({ sites }) =>
        sites
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((e) => {
                return { id: e.id, name: e.name, default_field: e.default_field };
            })
    );
};
siteAutocomplete.search = (search, pageSize) => {
    return doSwaggerCall('Sites', 'getSitesForUsers', {
        pageSize,
        search,
    }).then(({ sites }) =>
        sites
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((e) => {
                return { id: e.id, name: e.name, default_field: e.default_field };
            })
    );
};

export const companyAutocomplete = {};
companyAutocomplete.init = (ids) => {
    if (ids.length === 0 || (ids.length === 1 && ids[0] === '')) {
        return [];
    }
    return doSwaggerCall('Companies', 'getAllCompanies', {
        pageSize: ids.length,
        idList: ids,
    }).then(({ companies }) =>
        companies
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((e) => {
                return { id: e.id, name: e.name, company_years: e.company_years };
            })
    );
};
companyAutocomplete.search = (search, pageSize) => {
    return doSwaggerCall('Companies', 'getAllCompanies', {
        pageSize,
        search,
    }).then(({ companies }) =>
        companies
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((e) => {
                return { id: e.id, name: e.name, company_years: e.company_years };
            })
    );
};

export const siteInCompanyAutocomplete = {};
siteInCompanyAutocomplete.init = (ids) => {
    if (ids.length === 0 || (ids.length === 1 && ids[0] === '')) {
        return [];
    }
    return doSwaggerCall('Sites', 'getSitesForUsers', {
        pageSize: ids.length,
        idList: ids,
        optionalCompanyId: 1,
    }).then(({ sites }) =>
        sites
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((e) => {
                return { id: e.id, name: e.name };
            })
    );
};
siteInCompanyAutocomplete.search = (search, pageSize, { companyId }) => {
    return doSwaggerCall('Sites', 'getSitesForUsers', {
        pageSize,
        search,
        optionalCompanyId: companyId,
    }).then(({ sites }) =>
        sites
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((e) => {
                return { id: e.id, name: e.name };
            })
    );
};

export const scopeAutocomplete = {};
scopeAutocomplete.init = (ids) => {
    if (ids.length === 0 || (ids.length === 1 && ids[0] === '')) {
        return [];
    }
    return doSwaggerCall('Scopes', 'getAllScopes', {
        pageSize: ids.length,
        idList: ids,
        sourceLimitation: true,
    }).then(({ scopes }) =>
        scopes
            .sort((a, b) => a.order - b.order)
            .map((e) => {
                return { id: e.id, name: e.name, order: e.order };
            })
    );
};
scopeAutocomplete.search = (search, pageSize, scope) => {
    return doSwaggerCall('Scopes', 'getAllScopes', {
        pageSize,
        search,
        scope,
        sourceLimitation: true,
    }).then(({ scopes }) =>
        scopes
            .sort((a, b) => a.order - b.order)
            .map((e) => {
                return { id: e.id, name: e.name, order: e.order };
            })
    );
};

export const companyTagAutocomplete = {};
companyTagAutocomplete.init = (ids) => {
    if (ids.length === 0 || (ids.length === 1 && ids[0] === '')) {
        return [];
    }
    return doSwaggerCall('CompanyTags', 'getCompanyTags', {
        idList: ids,
        pageSize: ids.length,
    }).then(({ companyTags }) =>
        companyTags.map((e) => {
            return {
                id: e.id,
                name: e.name,
            };
        })
    );
};
companyTagAutocomplete.search = (search, pageSize) => {
    return doSwaggerCall('CompanyTags', 'getCompanyTags', {
        pageSize,
        search,
    }).then(({ companyTags }) =>
        companyTags.map((e) => {
            return { id: e.id, name: e.name };
        })
    );
};

export const datalineTagAutocomplete = {};
datalineTagAutocomplete.init = (ids) => {
    if (ids.length === 0 || (ids.length === 1 && ids[0] === '')) {
        return [];
    }
    return doSwaggerCall('DatalineTags', 'getAllDatalineTags', {
        idList: ids,
        pageSize: ids.length,
    }).then(({ datalineTags }) =>
        datalineTags.map((e) => {
            return {
                id: e.id,
                name: e.name,
            };
        })
    );
};
datalineTagAutocomplete.search = (search, pageSize) => {
    return doSwaggerCall('DatalineTags', 'getAllDatalineTags', {
        pageSize,
        search,
    }).then(({ datalineTags }) =>
        datalineTags.map((e) => {
            return { id: e.id, name: e.name };
        })
    );
};

export const intensityMetricsAutocomplete = {};
intensityMetricsAutocomplete.init = (ids) => {
    if (ids.length === 0 || (ids.length === 1 && ids[0] === '')) {
        return [];
    }
    return doSwaggerCall('IntensityMetrics', 'getAllIntensityMetrics', {
        idList: ids,
        pageSize: ids.length,
    }).then(({ intensityMetrics }) =>
        intensityMetrics.map((e) => {
            return {
                id: e.id,
                name: e.metric,
            };
        })
    );
};
intensityMetricsAutocomplete.search = (search, pageSize) => {
    return doSwaggerCall('IntensityMetrics', 'getAllIntensityMetrics', {
        pageSize,
        search,
    }).then(({ intensityMetrics }) =>
        intensityMetrics.map((e) => {
            return { id: e.id, name: e.metric };
        })
    );
};

export const scenarioAutocomplete = {};
scenarioAutocomplete.init = (ids) => {
    if (ids.length === 0 || (ids.length === 1 && ids[0] === '')) {
        return [];
    }
    return doSwaggerCall('Scenarios', 'getScenarios', {
        scenarioIdList: ids,
        limit: ids.length,
    }).then(({ scenarios }) =>
        scenarios.map((e) => {
            return {
                id: e.id,
                name: e.name,
            };
        })
    );
};
scenarioAutocomplete.search = (search, limit) => {
    return doSwaggerCall('Scenarios', 'getScenarios', {
        limit,
        search,
    }).then(({ scenarios }) =>
        scenarios.map((e) => {
            return { id: e.id, name: e.name };
        })
    );
};

export const supplierAutocomplete = {};
supplierAutocomplete.init = (ids) => {
    if (ids.length === 0 || (ids.length === 1 && ids[0] === '')) {
        return [];
    }
    return doSwaggerCall('Suppliers', 'getAllSuppliers', {
        idList: ids,
        pageSize: ids.length,
    }).then(({ suppliers }) =>
        suppliers.map((e) => {
            return {
                id: e.id,
                name: e.name,
            };
        })
    );
};
supplierAutocomplete.search = (search, pageSize) => {
    return doSwaggerCall('Suppliers', 'getAllSuppliers', {
        pageSize,
        search,
    }).then(({ suppliers }) =>
        suppliers.map((e) => {
            return { id: e.id, name: e.name };
        })
    );
};

export const dashboardTemplateAutocomplete = {};
// we want to display all dashboard templates here, so we don't use the ids
dashboardTemplateAutocomplete.init = () => {
    return doSwaggerCall('Dashboards', 'getDashboardTemplates', {}).then(({ dashboards }) =>
        dashboards.map((e) => {
            return { id: e.id, name: e.name, description: e.description, panels: e.panels };
        })
    );
};
// need pageSize in param for AutocompleteSelect
dashboardTemplateAutocomplete.search = (search, pageSize) => {
    return doSwaggerCall('Dashboards', 'getDashboardTemplates', {
        search,
    }).then(({ dashboards }) =>
        dashboards.map((e) => {
            return { id: e.id, name: e.name, description: e.description, panels: e.panels };
        })
    );
};

export const siteCopyAutocomplete = {};
siteCopyAutocomplete.init = (ids) => {
    if (ids.length === 0 || (ids.length === 1 && ids[0] === '')) {
        return [];
    }
    return doSwaggerCall('Sites', 'getSitesForUsers', {
        pageSize: ids.length,
        idList: ids,
        copyFlag: true,
    }).then(({ sites }) =>
        sites
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((e) => {
                return { id: e.id, name: e.name };
            })
    );
};
siteCopyAutocomplete.search = (search, pageSize, { companyId }) => {
    return doSwaggerCall('Sites', 'getSitesForUsers', {
        pageSize,
        search,
        optionalCompanyId: companyId,
        copyFlag: true,
    }).then(({ sites }) =>
        sites
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((e) => {
                return { id: e.id, name: e.name };
            })
    );
};

export const sourceForSurveyAutocomplete = {};
// we want to display all sources here, so we don't use the ids
sourceForSurveyAutocomplete.init = (ids, { token, categoryId }) => {
    if (ids.length === 0 || (ids.length === 1 && ids[0] === '')) {
        return [];
    }
    return doSwaggerCall('EmissionSources', 'getEmissionSourcesForSurvey', {
        token,
        categoryId,
    }).then(({ emissionSources }) =>
        numericSort(emissionSources).map((e) => {
            return {
                id: e.id,
                name: e.name,
                base_unit: e.base_unit,
                unit_conversion_logic: e.unit_conversion_logic,
                scope_id: e.scope_id,
                scope_name: e.scope_name,
                category_id: e.category_id,
                category_name: e.category_name,
                subcategory_id: e.subcategory_id,
                subcategory_name: e.subcategory_name,
            };
        })
    );
};

// need pageSize in param for AutocompleteSelect
sourceForSurveyAutocomplete.search = (search, pageSize, token, categoryId) => {
    return doSwaggerCall('EmissionSources', 'getEmissionSourcesForSurvey', {
        search,
        token,
        categoryId,
    }).then(({ emissionSources }) =>
        numericSort(emissionSources).map((e) => {
            return {
                id: e.id,
                name: e.name,
                base_unit: e.base_unit,
                unit_conversion_logic: e.unit_conversion_logic,
                scope_id: e.scope_id,
                scope_name: e.scope_name,
                category_id: e.category_id,
                category_name: e.category_name,
                subcategory_id: e.subcategory_id,
                subcategory_name: e.subcategory_name,
            };
        })
    );
};

// esg category autocomplete
export const esgCategoryAutocomplete = {};
esgCategoryAutocomplete.init = (ids) => {
    if (ids.length === 0 || (ids.length === 1 && ids[0] === '')) {
        return [];
    }
    return doSwaggerCall('EsgCategories', 'getAllEsgCategories', {
        pageSize: ids.length,
        idList: ids,
    }).then(({ esgCategories }) =>
        esgCategories
            .sort((a, b) => a.rank - b.rank)
            .map((e) => {
                return { id: e.id, name: e.name, rank: e.rank };
            })
    );
};

esgCategoryAutocomplete.search = (search, pageSize) => {
    return doSwaggerCall('EsgCategories', 'getAllEsgCategories', {
        pageSize,
        search,
    }).then(({ esgCategories }) =>
        esgCategories
            .sort((a, b) => a.rank - b.rank)
            .map((e) => {
                return { id: e.id, name: e.name, rank: e.rank };
            })
    );
};

// esg subcategory autocomplete
export const esgSubcategoryAutocomplete = {};
esgSubcategoryAutocomplete.init = (ids) => {
    if (ids.length === 0 || (ids.length === 1 && ids[0] === '')) {
        return [];
    }
    return doSwaggerCall('EsgSubcategories', 'getAllEsgSubcategories', {
        pageSize: ids.length,
        idList: ids,
    }).then(({ esgSubcategories }) =>
        esgSubcategories
            .sort((a, b) => a.rank - b.rank)
            .map((e) => {
                return {
                    id: e.id,
                    name: e.name,
                    rank: e.rank,
                    category_id: e.category_id,
                    category_name: e.category_name,
                };
            })
    );
};

esgSubcategoryAutocomplete.search = (search, pageSize, { esgCategoryId }) => {
    return doSwaggerCall('EsgSubcategories', 'getAllEsgSubcategories', {
        pageSize,
        search,
        esgCategoryId,
    }).then(({ esgSubcategories }) =>
        esgSubcategories
            .sort((a, b) => a.order - b.order)
            .map((e) => {
                return {
                    id: e.id,
                    name: e.name,
                    rank: e.rank,
                    category_id: e.category_id,
                    category_name: e.category_name,
                };
            })
    );
};

// esg disclosure autocomplete
export const esgDisclosureAutocomplete = {};
esgDisclosureAutocomplete.init = (ids) => {
    if (ids.length === 0 || (ids.length === 1 && ids[0] === '')) {
        return [];
    }
    return doSwaggerCall('EsgDisclosures', 'getAllEsgDisclosures', {
        pageSize: ids.length,
        idList: ids,
    }).then(({ esgDisclosures }) =>
        esgDisclosures
            .sort((a, b) => a.order - b.order)
            .map((e) => {
                return {
                    id: e.id,
                    name: e.name,
                    rank: e.rank,
                    subcategory_id: e.subcategory_id,
                    subcategory_name: e.subcategory_name,
                    category_id: e.category_id,
                    category_name: e.category_name,
                };
            })
    );
};

esgDisclosureAutocomplete.search = (search, pageSize, { esgSubcategoryId, esgCategoryId }) => {
    return doSwaggerCall('EsgDisclosures', 'getAllEsgDisclosures', {
        pageSize,
        search,
        esgCategoryId,
        esgSubcategoryId,
    }).then(({ esgDisclosures }) =>
        esgDisclosures
            .sort((a, b) => a.rank - b.rank)
            .map((e) => {
                return {
                    id: e.id,
                    name: e.name,
                    rank: e.rank,
                    category_id: e.category_id,
                    category_name: e.category_name,
                    subcategory_id: e.subcategory_id,
                    subcategory_name: e.subcategory_name,
                };
            })
    );
};

// esg question autocomplete
export const esgQuestionAutocomplete = {};
esgQuestionAutocomplete.init = (ids, { onlyNumeric = false }) => {
    if (ids.length === 0 || (ids.length === 1 && ids[0] === '')) {
        return [];
    }
    return doSwaggerCall('EsgQuestions', 'getAllEsgQuestions', {
        pageSize: ids.length,
        idList: ids,
        onlyNumeric,
    }).then(({ esgQuestions }) =>
        numericSort(esgQuestions).map((e) => {
            return {
                id: e.id,
                name: e.name,
                fields: e.fields,
                disclosure_id: e.disclosure_id,
                disclosure_name: e.disclosure_name,
                subcategory_id: e.subcategory_id,
                subcategory_name: e.subcategory_name,
                category_id: e.category_id,
                category_name: e.category_name,
            };
        })
    );
};

esgQuestionAutocomplete.search = (
    search,
    pageSize,
    { esgSubcategoryId, esgCategoryId, esgDisclosureId, onlyNumeric }
) => {
    return doSwaggerCall('EsgQuestions', 'getAllEsgQuestions', {
        pageSize,
        search,
        esgCategoryId,
        esgSubcategoryId,
        esgDisclosureId,
        onlyNumeric,
    }).then(({ esgQuestions }) =>
        numericSort(esgQuestions).map((e) => {
            return {
                id: e.id,
                name: e.name,
                fields: e.fields,
                category_id: e.category_id,
                category_name: e.category_name,
                subcategory_id: e.subcategory_id,
                subcategory_name: e.subcategory_name,
                disclosure_id: e.disclosure_id,
                disclosure_name: e.disclosure_name,
            };
        })
    );
};

// esg framework autocomplete
export const esgFrameworkAutocomplete = {};
esgFrameworkAutocomplete.init = (ids) => {
    if (ids.length === 0 || (ids.length === 1 && ids[0] === '')) {
        return [];
    }
    return doSwaggerCall('EsgFrameworks', 'getEsgFrameworks', {
        limit: ids.length, // pageSize
        idList: ids,
    }).then(({ esgFrameworks }) =>
        numericSort(esgFrameworks).map((e) => {
            return {
                id: e.id,
                name: e.name,
            };
        })
    );
};

esgFrameworkAutocomplete.search = (
    search,
    limit // pageSize
) => {
    return doSwaggerCall('EsgFrameworks', 'getEsgFrameworks', {
        limit,
        search,
    }).then(({ esgFrameworks }) =>
        numericSort(esgFrameworks).map((e) => {
            return {
                id: e.id,
                name: e.name,
            };
        })
    );
};

// esg framework with limitation autocomplete
export const esgFrameworkWithLimitationAutocomplete = {};
esgFrameworkWithLimitationAutocomplete.init = (ids) => {
    if (ids.length === 0 || (ids.length === 1 && ids[0] === '')) {
        return [];
    }
    return doSwaggerCall('EsgFrameworks', 'getAllEsgFrameworks', {
        pageSize: ids.length,
        idList: ids,
    }).then(({ frameworks }) =>
        numericSort(frameworks).map((e) => {
            return {
                id: e.id,
                name: e.name,
            };
        })
    );
};

esgFrameworkWithLimitationAutocomplete.search = (search, pageSize) => {
    return doSwaggerCall('EsgFrameworks', 'getAllEsgFrameworks', {
        search,
        pageSize,
    }).then(({ frameworks }) =>
        numericSort(frameworks).map((e) => {
            return {
                id: e.id,
                name: e.name,
            };
        })
    );
};

export const sitesInCompanyAutocomplete = {};
sitesInCompanyAutocomplete.init = (ids, { companyId }) => {
    if (ids.length === 0 || (ids.length === 1 && ids[0] === '')) {
        return [];
    }
    return doSwaggerCall('Sites', 'getSitesInCompany', {
        limit: ids.length,
        idList: ids,
        id: companyId,
    }).then(({ sites }) => [
        { id: 0, name: 'Corporate' }, // Include Corporate
        ...sites
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((e) => {
                return { id: e.id, name: e.name };
            }),
    ]);
};
sitesInCompanyAutocomplete.search = (search, limit, { companyId }) => {
    return doSwaggerCall('Sites', 'getSitesInCompany', {
        limit,
        search,
        id: companyId,
    }).then(({ sites }) => [
        { id: 0, name: 'Corporate' }, // Include Corporate
        ...sites
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((e) => {
                return { id: e.id, name: e.name };
            }),
    ]);
};

export const esgScenarioAutocomplete = {};
esgScenarioAutocomplete.init = (ids) => {
    if (ids.length === 0 || (ids.length === 1 && ids[0] === '')) {
        return [];
    }
    return doSwaggerCall('EsgScenarios', 'getEsgScenarios', {
        scenarioIdList: ids,
        limit: ids.length,
    }).then(({ scenarios }) =>
        scenarios.map((e) => {
            return {
                id: e.id,
                name: e.name,
            };
        })
    );
};
esgScenarioAutocomplete.search = (search, limit) => {
    return doSwaggerCall('EsgScenarios', 'getEsgScenarios', {
        limit,
        search,
    }).then(({ scenarios }) =>
        scenarios.map((e) => {
            return { id: e.id, name: e.name };
        })
    );
};
