import React from 'react';
import { useNavigate } from 'react-router-dom';
import GroupByComponent from './GroupByComponent';
import { PERMISSIONS } from '../../helpers/constans';
import { useAuth } from '../../hooks/useAuth';

const ResponsibilitySwitcherTab = ({ responsibility, type }) => {
    const { userPermissions } = useAuth();
    const navigate = useNavigate();

    const groupByOptions = {
        all: 'All',
        assignedToMe: 'Assigned to me',
        ownedByMe: 'Owned by me',
    };

    if (type === 'datalines') {
        if (!userPermissions.includes(PERMISSIONS.VIEW_OWNED)) {
            delete groupByOptions.ownedByMe;
        }
        if (!userPermissions.includes(PERMISSIONS.VIEW_ASSIGNED_TO)) {
            delete groupByOptions.assignedToMe;
        }
        if (!userPermissions.includes(PERMISSIONS.VIEW_DATALINES)) {
            delete groupByOptions.all;
        }
    }

    return (
        <GroupByComponent
            label="Responsibility"
            groupBy={responsibility === undefined ? 'all' : responsibility}
            setGroupBy={(value) => {
                if (value === 'assignedToMe') {
                    navigate(`/${type}/assignedtome`);
                    return;
                }
                if (value === 'ownedByMe') {
                    navigate(`/${type}/ownedbyme`);
                    return;
                }
                navigate(`/${type}`);
            }}
            search=""
            groupByOptions={groupByOptions}
        />
    );
};

export default ResponsibilitySwitcherTab;
