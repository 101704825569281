import {
    Card,
    CardContent,
    CardMedia,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ExtraFieldSelector from '../components/extrafieldselector/ExtraFieldSelector';
import { isValidConfig } from '../helpers/dashboardPanelValidationHelper';
import CommonButton from '../components/atom/CommonButton';
import { CHART_GROUPS, DASHBOARD_MENU_ITEMS } from '../helpers/constans';
import { DisplayPanel } from '../pages/dashboardtemplateedit/components/DashboardPanel';
import { doSwaggerCall } from '../hooks/useApi';
import { useGlobalFilters } from '../hooks/useGlobalFilters';

function AddDashboardPanelModal({
    showModal,
    MODAL_TYPE,
    confirm,
    panel = false,
    panelId = false,
}) {
    const closeAction = () => showModal(MODAL_TYPE.NONE);
    const [selectedPanel, setSelectedPanel] = useState(panel.visual_type || 'total_emissions');
    const [panelName, setPanelName] = useState(panel.name);
    const [panelDescription, setPanelDescription] = useState(panel.description);
    const [panelConfig, setPanelConfig] = useState(panel.config);
    const [selectedGroup, setSelectedGroup] = useState([]);
    const [step, setStep] = useState(panel ? 1 : 0);
    const [yearSumData, setYearSumData] = useState({});
    const { year, site } = useGlobalFilters();

    const filterMenuItemsByGroup = (menuItems, group) => {
        if (group.length === 0 || group.includes('All')) {
            return menuItems;
        }

        return menuItems.filter((item) => {
            const itemGroups = item.group || [];
            return group.every((g) => itemGroups.includes(g));
        });
    };

    const handleChipClick = (group) => {
        if (group === 'All') {
            setSelectedGroup(selectedGroup.length === CHART_GROUPS.length ? [] : [group]);
        } else {
            setSelectedGroup((prev) => {
                // If "All" is selected and a non-"All" chip is clicked, remove "All"
                if (prev.includes('All')) {
                    return [group];
                }
                return prev.includes(group)
                    ? prev.filter((item) => item !== group)
                    : [...prev, group];
            });
        }
        setSelectedPanel(null);
    };

    // only allow to select valid options
    const getValidGroups = (selectedGroups) => {
        if (selectedGroups.includes('All')) {
            return Object.values(CHART_GROUPS);
        }
        return Object.values(CHART_GROUPS).filter((group) => {
            return filterMenuItemsByGroup(DASHBOARD_MENU_ITEMS, selectedGroups).some((item) =>
                item.group.includes(group)
            );
        });
    };
    useEffect(() => {
        doSwaggerCall('Hierarchy', 'getSourceHierarchySum', {
            // TODO: params will be replaced by template filters later
            year,
            siteIdList: site,
            reportByTag: null,
        }).then((res) => {
            setYearSumData(res);
        });
    }, [year, site, setYearSumData]);

    const panelInformation = DASHBOARD_MENU_ITEMS.find((item) => item.value === selectedPanel);

    const panelData = {
        name: panelName,
        description: panelDescription,
        visual_type: selectedPanel,
        config: panelConfig,
    };

    return (
        <Dialog
            aria-labelledby="add-dashboard-panel-modal"
            open
            onClose={closeAction}
            fullWidth
            maxWidth="lg"
        >
            <DialogTitle>{!panel ? 'Add' : 'Edit'} Dashboard Item</DialogTitle>
            <DialogContent>
                {step === 0 && (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                autoComplete="off"
                                required
                                value={panelName || ''}
                                label="Chart name"
                                onChange={(e) => {
                                    setPanelName(e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                autoComplete="off"
                                value={panelDescription || ''}
                                label="Chart description"
                                onChange={(e) => {
                                    setPanelDescription(e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {Object.entries(CHART_GROUPS).map(([key, value]) => (
                                <Chip
                                    key={key}
                                    label={value}
                                    disabled={
                                        !getValidGroups(selectedGroup).includes(value) &&
                                        value !== 'All'
                                    }
                                    variant="outlined"
                                    onClick={() => handleChipClick(value)}
                                    sx={{
                                        cursor: 'pointer',
                                        m: '2px',
                                        variant: 'outlined',
                                        border: selectedGroup.includes(value)
                                            ? '1px solid #3f51b5'
                                            : '1px solid #ccc',
                                    }}
                                />
                            ))}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="caption">Select a chart type</Typography>
                            <div
                                style={{
                                    marginTop: 2,
                                    maxHeight: '400px',
                                    overflowY: 'auto',
                                }}
                            >
                                <Grid container spacing={2}>
                                    {filterMenuItemsByGroup(
                                        DASHBOARD_MENU_ITEMS,
                                        selectedGroup
                                    ).map((item) => (
                                        <Grid item key={item.value} xs={4} md={3}>
                                            <Card
                                                elevation={2}
                                                style={{
                                                    border:
                                                        selectedPanel === item.value
                                                            ? '1px solid #3f51b5'
                                                            : '1px dashed #ccc',
                                                    padding: 8,
                                                    marginRight: 8,
                                                    cursor: 'pointer',
                                                    height: '100%',
                                                    minWidth: 200,
                                                }}
                                                onClick={() => {
                                                    setSelectedPanel(item.value);
                                                    setPanelConfig({});
                                                }}
                                            >
                                                <CardContent style={{ padding: 4 }}>
                                                    <Typography
                                                        variant="body1"
                                                        component="div"
                                                        style={{ fontWeight: 'bold' }}
                                                    >
                                                        {item.label}
                                                    </Typography>
                                                    <Typography
                                                        gutterBottom
                                                        variant="body1"
                                                        component="div"
                                                        minHeight={50}
                                                        style={{
                                                            fontSize: 12,
                                                            wordWrap: 'break-word',
                                                            whiteSpace: 'pre-wrap',
                                                        }}
                                                    >
                                                        {item.description}
                                                    </Typography>
                                                </CardContent>
                                                <CardMedia
                                                    component="img"
                                                    image={`/dashboardpreview/${item.value}.png`}
                                                    style={{
                                                        height: 'auto',
                                                        width: '100%',
                                                        objectFit: 'contain',
                                                    }}
                                                />
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                )}
                {step === 1 && panelInformation && (
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1">
                                Configuration for {panelInformation.label}
                            </Typography>
                            <TextField
                                fullWidth
                                autoComplete="off"
                                required
                                value={panelName || ''}
                                label="Chart name"
                                onChange={(e) => {
                                    setPanelName(e.target.value);
                                }}
                                sx={{ mb: 1, mt: 1 }}
                            />
                            <TextField
                                fullWidth
                                autoComplete="off"
                                value={panelDescription || ''}
                                label="Chart description"
                                onChange={(e) => {
                                    setPanelDescription(e.target.value);
                                }}
                                sx={{ mb: 1 }}
                            />
                            <ExtraFieldSelector
                                selectedPanel={selectedPanel}
                                panelConfig={panelConfig}
                                setPanelConfig={setPanelConfig}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1" style={{ marginBottom: '16px' }}>
                                Preview
                            </Typography>
                            {!isValidConfig(selectedPanel, panelConfig) && (
                                <Typography
                                    variant="body1"
                                    color="error"
                                    style={{ marginBottom: '16px' }}
                                >
                                    Please fill in all required fields
                                </Typography>
                            )}
                            {isValidConfig(selectedPanel, panelConfig) && (
                                <DisplayPanel
                                    visible
                                    isPreview
                                    yearSumData={yearSumData}
                                    panel={panelData}
                                    setChartData={() => {
                                        // ignore the call here
                                    }}
                                />
                            )}
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
            <DialogActions>
                <CommonButton
                    onClick={() => {
                        if (step === 0) {
                            showModal(MODAL_TYPE.NONE);
                        } else {
                            setStep(0);
                        }
                    }}
                    label={step === 0 ? 'Close' : 'Back'}
                    buttonType="secondary"
                />
                {step === 0 && (
                    <CommonButton
                        onClick={() => setStep(1)}
                        disabled={!selectedPanel}
                        label="Next"
                        buttonType="primary"
                    />
                )}
                {step === 1 && (
                    <CommonButton
                        onClick={() => {
                            confirm(
                                {
                                    name: panelName,
                                    description: panelDescription,
                                    visual_type: selectedPanel,
                                    config: panelConfig,
                                },
                                panelId,
                                panelConfig,
                                setPanelConfig
                            );
                            showModal(MODAL_TYPE.NONE);
                        }}
                        type="submit"
                        disabled={
                            panelName === '' ||
                            typeof panelName === 'undefined' ||
                            !selectedPanel ||
                            !isValidConfig(selectedPanel, panelConfig)
                        }
                        label="Save"
                        buttonType="primary"
                    />
                )}
            </DialogActions>
        </Dialog>
    );
}

export default AddDashboardPanelModal;
