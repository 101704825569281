import {
    ButtonGroup,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import Add from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import FloatInput from '../../../components/atom/FloatInput';
import { doSwaggerCall, useApi } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';
import { MODAL_TYPE, useModals } from '../../../hooks/useModal';
import BlockWithTitle from '../../../components/atom/BlockWithTitle';
import CommonButton from '../../../components/atom/CommonButton';
import AutocompleteSelect from '../../../components/atom/AutoCompleteSelect';
import { sitesInCompanyAutocomplete } from '../../../autocomplete';

const IntensityMetricEditor = ({
    companyId,
    siteId, // siteId is 0 for company level
    localCompanyYear,
    setLocalCompanyYear,
    availableYears,
}) => {
    const { notifyError, notifySuccess } = useNotify();
    const { showModal } = useModals();
    const [site, setSite] = useState(siteId);
    const navigate = useNavigate();
    const [data, loading, error, setData, reloadData] = useApi(
        'IntensityMetrics',
        'getIntensityMetrics',
        {
            companyId,
            siteId,
            year: localCompanyYear,
        }
    );

    const onSubmit = async (values) => {
        const req = doSwaggerCall(
            'IntensityMetrics',
            'saveIntensityMetrics',
            { companyId, siteId, year: localCompanyYear },
            {
                intensityMetricList: values.map((intensityMetric) => ({
                    metric: intensityMetric.metric,
                    value: parseFloat(intensityMetric.value),
                })),
            }
        );
        req.then(() => {
            notifySuccess('Intensity metrics updated');
            reloadData();
        }).catch((err) => {
            notifyError(err);
        });
    };

    const deleteIntensityMetric = useCallback((metric) => {
        doSwaggerCall('IntensityMetrics', 'deleteIntensityMetric', { companyId, metric })
            .then(() => {
                notifySuccess('Intensity metric deleted from every years');
                reloadData();
            })
            .catch(() => {
                notifyError('Cannot delete intensity metric');
            });
    }, []);

    useEffect(() => {
        if (error) {
            notifyError(error);
        }
    }, [error, notifyError]);

    if (loading) {
        return true;
    }

    const isValidData = (values) => {
        const metrics = values.map((value) => value.metric.toLowerCase());
        const isDuplicateName = metrics.some((item, index) => metrics.indexOf(item) !== index);
        if (isDuplicateName) {
            return false;
        }
        return true;
    };

    const initData = {
        intensityMetrics: data.uniqueIntensityMetrics.map((metric) => ({
            metric,
            value: data.intensityMetrics.find((e) => e.metric === metric)?.value || '',
            disabled: true,
        })),
    };
    return (
        <BlockWithTitle title="Intensity metrics">
            <Grid item xs={12} align="right">
                <ButtonGroup>
                    <CommonButton
                        onClick={() => {
                            showModal(MODAL_TYPE.COPY_TO_ANOTHER_YEAR, {
                                title: `Are you sure you want to copy intensity metrics from ${localCompanyYear}?`,
                                content: 'This action is irreversible.',
                                companyId,
                                siteId,
                                year: localCompanyYear,
                                availableYears,
                                type: 'intensity metrics',
                                reloadData: () => reloadData(),
                            });
                        }}
                        label="Copy to ..."
                        buttonType="outlined"
                    />
                    <CommonButton
                        onClick={() => {
                            showModal(MODAL_TYPE.COPY_FROM_ANOTHER_YEAR, {
                                title: `Are you sure you want to copy intensity metrics to ${localCompanyYear}?`,
                                content: 'This action is irreversible.',
                                descriptionType: 'intensity metrics',
                                companyId,
                                siteId,
                                year: localCompanyYear,
                                availableYears,
                                reloadData: () => reloadData(),
                            });
                        }}
                        label="Copy from ..."
                        buttonType="outlined"
                    />
                </ButtonGroup>
            </Grid>
            <Grid container item spacing={2} alignItems="center">
                <Grid item xs={8}>
                    <FormControl fullWidth>
                        <InputLabel id="year-select">Year</InputLabel>
                        <Select
                            fullWidth
                            labelId="year-select"
                            id="demo-simple-select"
                            value={localCompanyYear}
                            size="small"
                            label="Year"
                            onChange={(e) => {
                                setLocalCompanyYear(parseInt(e.target.value, 10));
                            }}
                        >
                            {availableYears.map((yearItem) => (
                                <MenuItem key={yearItem} value={yearItem}>
                                    {yearItem}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <AutocompleteSelect
                        initCall={sitesInCompanyAutocomplete.init}
                        autocompleteCall={sitesInCompanyAutocomplete.search}
                        disableClearable
                        value={site}
                        setValue={(ev) => {
                            setSite(ev);
                            if (ev === 0) {
                                navigate(`/companies/edit/${companyId}/intensity`);
                            } else {
                                navigate(`/companies/${companyId}/sites/edit/${ev}/intensity`);
                            }
                        }}
                        searchParams={{
                            companyId,
                        }}
                    >
                        <TextField label="Site" size="small" />
                    </AutocompleteSelect>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                {
                    // enableReinitialize always false here
                }
                <Formik initialValues={initData} enableReinitialize={false}>
                    {({ isSubmitting, values, setFieldValue }) => (
                        <Form>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell width="48%">Metric</TableCell>
                                        <TableCell width="48%">Value</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {values.intensityMetrics.map(
                                        ({ metric, value, disabled }, idx) => (
                                            <TableRow key={idx}>
                                                <TableCell>
                                                    <TextField
                                                        fullWidth
                                                        autoComplete="off"
                                                        type="text"
                                                        required
                                                        value={metric}
                                                        label="metric name"
                                                        onChange={(ev) => {
                                                            setFieldValue(
                                                                `intensityMetrics[${idx}]`,
                                                                {
                                                                    metric: ev.target.value,
                                                                    value,
                                                                }
                                                            );
                                                        }}
                                                        disabled={disabled || false}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <FloatInput
                                                        fullWidth
                                                        label={`${metric} value`}
                                                        type="number"
                                                        value={value}
                                                        onChange={(ev) => {
                                                            setFieldValue(
                                                                `intensityMetrics[${idx}]`,
                                                                {
                                                                    metric,
                                                                    value: ev.target.rawValue,
                                                                }
                                                            );
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton
                                                        onClick={() => {
                                                            showModal(MODAL_TYPE.CONFIRMATION, {
                                                                title: `Are you sure you want to delete this intensity metrics from every year?`,
                                                                content:
                                                                    'This action is irreversible.',
                                                                warningContent:
                                                                    'Deleting a metric will remove it from EVERY year, if the intention was to not set the metric to a specific year, just empty out the input and save the metrics.',
                                                                confirm: () =>
                                                                    deleteIntensityMetric(metric),
                                                            });
                                                        }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    )}
                                    <TableRow>
                                        <TableCell colSpan={2} />
                                        <TableCell>
                                            <IconButton
                                                onClick={() => {
                                                    setFieldValue('intensityMetrics', [
                                                        ...values.intensityMetrics,
                                                        {
                                                            metric: '',
                                                            value: '',
                                                        },
                                                    ]);
                                                }}
                                            >
                                                <Add />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <Grid item xs={12}>
                                <CommonButton
                                    fullWidth
                                    disabled={isSubmitting || !isValidData(values.intensityMetrics)}
                                    onClick={() => {
                                        showModal(MODAL_TYPE.CONFIRMATION, {
                                            title: `Are you sure you want to edit intensity metrics?`,
                                            content: 'This action is irreversible.',
                                            confirm: () => onSubmit(values.intensityMetrics),
                                        });
                                    }}
                                    label="Save"
                                    buttonType="primary"
                                />
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Grid>
        </BlockWithTitle>
    );
};

export default IntensityMetricEditor;
