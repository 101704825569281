import { ResponsiveBar } from '@nivo/bar';
import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useDarkMode } from '../../../../hooks/useDarkMode';
import {
    BARCHART_PROPS,
    customAxisColor,
    customNivoTheme,
} from '../../../../helpers/chartStyleHelper';
import { formatNumber } from '../../../../helpers/convertUnits';

const EsgQuestionBarChart = ({
    data,
    esgQuestionName,
    keys,
    axisLeftLegend,
    format,
    label,
    indexBy,
    fieldName,
}) => {
    const { darkMode } = useDarkMode();

    const formatAxis = (value) => {
        let response = value;
        // trimStart for remove starting space
        if (value[0] === ' ') {
            response = value.trimStart();
        }
        if (response.length > 12) {
            response = `${response.substring(0, 12)}...`;
        }
        if (data.length > 15) {
            response = '';
            const words = value.trimStart().split(' ');
            for (let i = 0; i < words.length - 2; i++) {
                response += Number.isNaN(parseFloat(words[i])) ? `${words[i][0]}` : `${words[i]}`;
            }
            if (response.charAt(response.length - 1) === '(') {
                response = response.slice(0, -1);
            }
        }
        return response;
    };

    return (
        <Grid
            item
            xs={12}
            style={{
                height: '40vh',
                marginBottom: '2vh',
                marginRight: '-25px',
                marginLeft: '5px',
            }}
        >
            <Grid item xs={12}>
                <Typography variant="h5">ESG question: {esgQuestionName}</Typography>
                {fieldName && <Typography variant="h5">Field: {fieldName}</Typography>}
            </Grid>
            <ResponsiveBar
                theme={customNivoTheme(darkMode)}
                colors={BARCHART_PROPS.colors}
                enableLabel={BARCHART_PROPS.enableLabel}
                borderRadius={BARCHART_PROPS.borderRadius}
                borderWidth={BARCHART_PROPS.borderWidth}
                labelSkipHeight={BARCHART_PROPS.labelSkipHeight}
                borderColor={BARCHART_PROPS.borderColor}
                labelTextColor={BARCHART_PROPS.labelTextColor}
                padding={BARCHART_PROPS.padding}
                labelSkipWidth={BARCHART_PROPS.labelSkipWidth}
                data={data}
                keys={keys}
                groupMode="grouped"
                indexBy={indexBy}
                valueFormat={BARCHART_PROPS.valueFormat}
                margin={{ right: 60, bottom: 60, left: 85, top: 20 }}
                label={label}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickRotation: 33,
                    legendOffset: 44,
                    tickPadding: 5,
                    format: (value) => {
                        return formatAxis(value);
                    },
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: axisLeftLegend,
                    legendPosition: 'middle',
                    legendOffset: -75,
                    format,
                    renderTick: (props) => {
                        return (
                            <text
                                style={{
                                    fontWeight: 'bold',
                                }}
                                textAnchor="end"
                                fill={customAxisColor(darkMode)}
                                fontSize={12}
                                x={props.x - 4}
                                y={props.y + 4}
                            >
                                {formatNumber(props.value)}
                            </text>
                        );
                    },
                }}
                valueScale={{ type: 'linear' }}
            />
        </Grid>
    );
};

export default EsgQuestionBarChart;
