import React from 'react';
import {
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SkeletonTable from '../../../components/atom/SkeletonTable';
import { TABLE_ROW_COUNT_OPTIONS } from '../../../helpers/constans';
import { handleChangeRowsPerPage, handlePageChange } from '../../../helpers/handleTableFunctions';
import SortableTableCell from '../../../components/atom/SortableTableCell';
import useNotify from '../../../hooks/useNotify';

const AirportsTable = ({
    rows,
    limit,
    page,
    setParams,
    total,
    loading,
    sort,
    sortDir,
    setSorting,
}) => {
    const { notifySuccess } = useNotify();
    return (
        <Grid item xs={12}>
            <Table>
                <TableHead>
                    <TableRow>
                        <SortableTableCell
                            arrow={sort === 'short' ? sortDir : ''}
                            onClick={() => setSorting('short')}
                        >
                            Airport code
                        </SortableTableCell>
                        <SortableTableCell
                            arrow={sort === 'city' ? sortDir : ''}
                            onClick={() => setSorting('city')}
                        >
                            City
                        </SortableTableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ? (
                        <SkeletonTable rows={limit} cells={3} />
                    ) : (
                        rows?.map((row) => (
                            <React.Fragment key={row.id}>
                                <TableRow>
                                    <TableCell width="50%">{row.short}</TableCell>
                                    <TableCell width="45%">{row.city || '-'}</TableCell>
                                    <TableCell width="5%" align="right">
                                        <Tooltip title="Copy to clipboard" arrow placement="left">
                                            <IconButton
                                                size="small"
                                                disableRipple
                                                sx={{
                                                    '&:hover': {
                                                        color: 'text.primary',
                                                    },
                                                }}
                                                onClick={() => {
                                                    navigator.clipboard.writeText(row.short);
                                                    notifySuccess('Copied to clipboard!');
                                                }}
                                            >
                                                <ContentCopyIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))
                    )}
                </TableBody>
            </Table>
            <TablePagination
                count={total || 0}
                rowsPerPage={limit}
                component="div"
                page={page}
                onPageChange={(_, newPage) => handlePageChange(newPage, setParams, limit)}
                rowsPerPageOptions={TABLE_ROW_COUNT_OPTIONS}
                onRowsPerPageChange={(event) => handleChangeRowsPerPage(event, setParams)}
            />
        </Grid>
    );
};

export default AirportsTable;
