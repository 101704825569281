import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const circularProgressStyles = {
    position: 'absolute',
    top: 0,
    left: 0,
};

const ProgressSvg = ({ size, value, style }) => {
    return (
        <Box
            sx={{
                position: 'relative',
                display: 'inline-flex',
                width: size,
                height: size,
                ...style,
            }}
        >
            <CircularProgress
                variant="determinate"
                value={100}
                sx={{
                    ...circularProgressStyles,
                    color: 'rgb(230,230,230)',
                }}
                size={size}
            />
            <CircularProgress
                variant="determinate"
                value={value}
                sx={{
                    ...circularProgressStyles,
                    color: 'rgb(33, 150, 243)',
                }}
                size={size}
            />
        </Box>
    );
};

export default ProgressSvg;
