import React, { useEffect, useState } from 'react';
import { Grid, LinearProgress, Typography } from '@mui/material';
import useNotify from '../../../hooks/useNotify';
import { doSwaggerCall } from '../../../hooks/useApi';
import EsgQuestionLineChart from './charts/EsgQuestionLineChart';

const ChartWrapper = ({
    fromYear,
    toYear,
    esgQuestionKeys,
    esgQuestionLineData,
    esgQuestionFieldMeasures,
}) => {
    // fill base years array from fromYear to toYear
    const baseYears = Array.from({ length: toYear - fromYear + 1 }, (_, i) => fromYear + i);
    // filter years that are not in the data
    const yearsInData = esgQuestionLineData.reduce((acc, curr) => {
        Object.keys(curr).forEach((key) => {
            if (key !== 'id' && key !== 'label' && key !== 'measure') {
                if (!acc.includes(key)) {
                    acc.push(key);
                }
            }
        });
        return acc;
    }, []);
    const years = baseYears.filter((year) => yearsInData.includes(year.toString()));

    return esgQuestionKeys.map((key, index) => {
        return (
            <EsgQuestionLineChart
                key={years}
                data={esgQuestionLineData.filter((item) => item.label === key)}
                fieldName={key}
                axisLeftLegend={esgQuestionFieldMeasures[index]}
            />
        );
    });
};

const BySelectedEsgLineQuestion = ({
    esgQuestion,
    sites,
    includeTotal,
    fromYear,
    toYear,
    fields,
    setChartData,
}) => {
    const { notifyError } = useNotify();
    const [esgQuestionLineData, setEsgQuestionLineData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [esgQuestionKeys, setEsgQuestionKeys] = useState([]);
    const [esgQuestionFieldMeasures, setEsgQuestionFieldMeasures] = useState([]);
    const [esgQuestionName, setEsgQuestionName] = useState('');

    useEffect(() => {
        doSwaggerCall(
            'Dashboards',
            'getDataByEsgQuestionForLineChart',
            {
                esgQuestion,
                siteIdList: sites,
                includeTotal,
                startYear: fromYear,
                endYear: toYear,
            },
            { fields }
        )
            .then((res) => {
                setEsgQuestionName(res.esgQuestionName);
                setEsgQuestionLineData(
                    res.esgData?.reduce((acc, curr) => {
                        // Find existing entry by site (id) and label
                        let existing = acc.find(
                            (item) => item.id === curr.id && item.label === curr.label
                        );

                        // If not found, create a new entry
                        if (!existing) {
                            existing = {
                                id: curr.id,
                                label: curr.label,
                            };
                            acc.push(existing);
                        }

                        // Add yearly data to the existing or new entry
                        Object.keys(curr).forEach((key) => {
                            existing[key] = curr[key];
                        });

                        return acc;
                    }, [])
                );
                setEsgQuestionKeys(res.esgQuestionFields.map((field) => field.label));
                setEsgQuestionFieldMeasures(
                    res.esgQuestionFields.map((field) => field.defaultMeasure)
                );
                setChartData(res.esgData);
                setLoading(false);
            })
            .catch((error) => {
                notifyError(error);
            });
    }, [
        setLoading,
        setEsgQuestionLineData,
        setEsgQuestionKeys,
        setEsgQuestionFieldMeasures,
        esgQuestion,
        sites,
        includeTotal,
        fromYear,
        toYear,
        JSON.stringify(fields),
        setChartData,
    ]);

    return (
        <Grid item xs={12}>
            {esgQuestionLineData?.length === 0 ? (
                <Grid item>
                    {loading ? <LinearProgress sx={{ mt: 0.25 }} /> : <h5>No data available</h5>}
                </Grid>
            ) : (
                <>
                    <Grid item xs={12}>
                        <Typography variant="h5">{esgQuestionName}</Typography>
                    </Grid>
                    <ChartWrapper
                        fromYear={fromYear}
                        toYear={toYear}
                        esgQuestionKeys={esgQuestionKeys}
                        esgQuestionLineData={esgQuestionLineData}
                        esgQuestionName={esgQuestionName}
                        esgQuestionFieldMeasures={esgQuestionFieldMeasures}
                    />
                </>
            )}
        </Grid>
    );
};

export default BySelectedEsgLineQuestion;
