import React, { useEffect, useState } from 'react';
import { Grid, LinearProgress, Typography } from '@mui/material';
import useNotify from '../../../hooks/useNotify';
import { doSwaggerCall } from '../../../hooks/useApi';
import EsgQuestionLineChart from './charts/EsgQuestionLineChart';

const BySelectedEsgScenario = ({ scenario, esgQuestion, fields, setChartData }) => {
    const { notifyError } = useNotify();
    const [scenarioLineData, setScenarioLineData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        doSwaggerCall(
            'Dashboards',
            'getDataForEsgScenarioLineChart',
            {
                scenarioId: scenario,
                esgQuestionId: esgQuestion,
            },
            { fields }
        )
            .then((res) => {
                setScenarioLineData(res);
                setChartData(res.scenarioData);
                setLoading(false);
            })
            .catch((error) => {
                notifyError(error);
                setLoading(false);
            });
    }, [scenario, esgQuestion, JSON.stringify(fields), setChartData, notifyError]);

    return (
        <Grid item xs={12}>
            {scenarioLineData.length === 0 ? (
                <Grid item>
                    {loading ? (
                        <LinearProgress sx={{ mt: 0.25 }} />
                    ) : (
                        <Typography>No data available</Typography>
                    )}
                </Grid>
            ) : (
                <>
                    <Grid item>
                        <Typography variant="h5">{scenarioLineData.questionName}</Typography>
                    </Grid>
                    {scenarioLineData.scenarioData.map((data, index) => (
                        <EsgQuestionLineChart
                            key={index}
                            data={[data]}
                            fieldName={data?.data[0]?.fieldName}
                            axisLeftLegend={data.measure}
                        />
                    ))}
                </>
            )}
        </Grid>
    );
};

export default BySelectedEsgScenario;
