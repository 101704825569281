import { TextField } from '@mui/material';
import React from 'react';
import AutocompleteSelect from '../../../components/atom/AutoCompleteSelect';
import { esgFrameworkWithLimitationAutocomplete } from '../../../autocomplete';
import { useEsgFrameworkFilters } from '../../../hooks/useEsgFrameworkFilters';

function EsgFrameworkFilter() {
    const { esgFramework, setEsgFrameworkFilters, setEdited } = useEsgFrameworkFilters();

    return (
        <AutocompleteSelect
            initCall={esgFrameworkWithLimitationAutocomplete.init}
            autocompleteCall={esgFrameworkWithLimitationAutocomplete.search}
            value={esgFramework}
            setValue={(ev, getItem) => {
                const item = getItem(ev);
                setEsgFrameworkFilters({
                    esgFrameworkFilter: item?.id || null,
                    esgFrameworkNameFilter: item?.name || null,
                });
                setEdited(false);
            }}
        >
            <TextField label="ESG Framework" fullWidth size="small" />
        </AutocompleteSelect>
    );
}

export default EsgFrameworkFilter;
