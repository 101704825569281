import React, { useCallback } from 'react';
import {
    DialogActions,
    DialogContentText,
    DialogContent,
    DialogTitle,
    Dialog,
    Grid,
    Alert,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useGlobalFilters } from '../hooks/useGlobalFilters';
import { doSwaggerCall } from '../hooks/useApi';
import { useAuth } from '../hooks/useAuth';
import useNotify from '../hooks/useNotify';
import CommonButton from '../components/atom/CommonButton';
import { useEsgFilters } from '../hooks/useEsgFilters';
import { useEsgFrameworkFilters } from '../hooks/useEsgFrameworkFilters';

const IncorrectDatalinesCountModal = ({
    title,
    content,
    incorrectList,
    companyId,
    warningContent,
    showModal,
    MODAL_TYPE,
}) => {
    const closeAction = () => showModal(MODAL_TYPE.NONE);

    const navigate = useNavigate();
    const { setSwitchIntoCompany, setAccessToken, user } = useAuth();
    const { setSourceFilters } = useGlobalFilters();
    const { setEsgFilters } = useEsgFilters();
    const { setEsgFrameworkFilters } = useEsgFrameworkFilters();
    const { notifyError, notifySuccess } = useNotify();

    const switchAndNavigate = useCallback(
        (year) => {
            doSwaggerCall('Auth', 'switchCompany', {
                companyId,
            })
                .then((data) => {
                    const { token, ...otherUserData } = data.user;
                    setSwitchIntoCompany(otherUserData, year);
                    setAccessToken(token);
                    setSourceFilters({
                        scopeFilter: null,
                        categoryFilter: null,
                        subcategoryFilter: null,
                        emissionSourceFilter: null,
                    });
                    setEsgFilters({
                        esgCategoryFilter: null,
                        esgSubcategoryFilter: null,
                        esgDisclosureFilter: null,
                        esgQuestionFilter: null,
                    });
                    setEsgFrameworkFilters({
                        esgFrameworkFilter: null,
                    });
                    navigate('/datalines/incorrect');
                    showModal(MODAL_TYPE.NONE);
                    if (companyId !== user.company_id) {
                        notifySuccess('Switched into company');
                    }
                })
                .catch((error) => {
                    notifyError(error);
                });
        },
        [setSwitchIntoCompany, setAccessToken]
    );

    return (
        <Dialog
            open
            onClose={closeAction}
            aria-labelledby="incorrect-datalines-dialog-title"
            aria-describedby="incorrect-datalines-dialog-description"
        >
            <DialogTitle id="incorrect-datalines-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="incorrect-datalines-dialog-description" sx={{ mb: 2 }}>
                    {content}
                </DialogContentText>
                <DialogContentText id="incorrect-datalines-dialog-description">
                    {incorrectList.map((e) => {
                        return (
                            <Grid key={e}>
                                <CommonButton
                                    onClick={() => {
                                        switchAndNavigate(e.incorrectDatalinesYear);
                                    }}
                                    label={`In ${e.incorrectDatalinesYear} you have ${e.incorrectDatalinesCount} incorrect datalines.`}
                                    buttonType="text"
                                />
                            </Grid>
                        );
                    })}
                </DialogContentText>
                {warningContent && (
                    <DialogContentText id="alert-dialog-warning">
                        <Alert sx={{ mt: 1 }} severity="info">
                            View datalines occurs switch into company!
                        </Alert>
                    </DialogContentText>
                )}
            </DialogContent>
            <DialogActions>
                <CommonButton
                    onClick={closeAction}
                    autoFocus
                    label="Cancel"
                    buttonType="secondary"
                />
            </DialogActions>
        </Dialog>
    );
};

export default IncorrectDatalinesCountModal;
