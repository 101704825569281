import React, { useEffect, useState } from 'react';
import { Grid, LinearProgress } from '@mui/material';
import useNotify from '../../../hooks/useNotify';
import { doSwaggerCall } from '../../../hooks/useApi';
import { useGlobalFilters } from '../../../hooks/useGlobalFilters';
import { useAuth } from '../../../hooks/useAuth';
import RadialBar from './charts/RadialBar';
import { formatNumber } from '../../../helpers/convertUnits';

async function convertAndSetRadialbar(siteIdList, year, requirements, setRadialbarData, tagFilter) {
    // get all datalines that has any data (exclude zero)
    const hierarchyData = [];
    for (let i = 0; i < siteIdList.length; i++) {
        const h = await doSwaggerCall('Hierarchy', 'getSourceHierarchy', {
            siteIdList: [siteIdList[i] === null ? 0 : siteIdList[i]],
            year,
            hasanydatalines: true,
            excludezeroco2e: true,
            reportByTag: tagFilter !== 0 ? tagFilter : null,
        });
        function walk(item) {
            if (item.children && item.children.length > 0) {
                item.children.forEach((subItem) => walk(subItem));
            }
            hierarchyData.push({
                id: item.id,
                type: item.type,
                site_id: siteIdList[i],
            });
        }
        h.forEach((item) => walk(item));
    }

    // convert and set RadialchartData
    const forRadialbar = [];
    // [completed, uncompleted] for every scope
    const completedScopeStats = [
        { id: 1, name: 'Scope 1', completed: [0, 0] },
        { id: 2, name: 'Scope 2 (Market-Based)', completed: [0, 0] },
        { id: 4, name: 'Scope 2 (Location-Based)', completed: [0, 0] },
        { id: 3, name: 'Scope 3', completed: [0, 0] },
        { id: 5, name: 'Short-Term Carbon Cycle', completed: [0, 0] },
    ];
    requirements.forEach((req) => {
        const scope = req.scopeId;
        const completed =
            typeof hierarchyData.find(
                (e) => req.levelId === e.id && req.levelType === e.type && e.site_id === req.siteId
            ) !== 'undefined';
        if (completed) {
            completedScopeStats.find((c) => c.id === scope).completed[0] += 1;
        } else {
            completedScopeStats.find((c) => c.id === scope).completed[1] += 1;
        }
    });

    let allCompleted = 0;
    completedScopeStats.forEach((scopeStat) => {
        allCompleted += scopeStat.completed[0];
    });
    const allRequirements = requirements.length;
    const completionOverallPercentage = formatNumber(
        ((allCompleted / allRequirements) * 100).toFixed(2)
    );
    forRadialbar.push({
        id: 0,
        data: [
            {
                x: `Overall (${completionOverallPercentage}%)`,
                y: completionOverallPercentage,
            },
        ],
    });
    // display radial bar only if scope has at least one requirement
    const filteredCompletedScopeStats = completedScopeStats.filter(
        (e) => !(e.completed[0] === 0 && e.completed[1] === 0)
    );
    for (let j = 0; j < filteredCompletedScopeStats.length; j++) {
        const completed = filteredCompletedScopeStats[j].completed[0];
        const unCompleted = filteredCompletedScopeStats[j].completed[1];
        const completionPercentage = formatNumber(
            ((completed / (completed + unCompleted)) * 100).toFixed(2)
        );
        forRadialbar.push({
            id: filteredCompletedScopeStats[j].id,
            data: [
                {
                    x: `${filteredCompletedScopeStats[j].name} (${completionPercentage}%)`,
                    y: completionPercentage,
                },
            ],
        });
    }
    setRadialbarData(forRadialbar);
}

const DataCompletion = ({ tagFilter }) => {
    const { notifyError } = useNotify();
    const { user } = useAuth();
    const { year, site } = useGlobalFilters();
    const [radialbarData, setRadialbarData] = useState([]);
    const [requirements, setRequirements] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        doSwaggerCall('Requirements', 'getRequirements', {
            id: user.company_id,
            siteIdList: site,
            year,
        })
            .then((res) => {
                setRequirements(res.requirements);
                setLoading(false);
            })
            .catch((error) => {
                notifyError(error);
            });
    }, [year, site, user, setRequirements, setLoading]);

    useEffect(() => {
        if (requirements.length === 0) {
            setRadialbarData([]);
            return;
        }

        const requirementSites = [...new Set(requirements.map((e) => e.siteId))];
        setLoading(true);
        // convert and set RadialbarData
        convertAndSetRadialbar(requirementSites, year, requirements, setRadialbarData, tagFilter)
            .then(() => {
                setLoading(false);
            })
            .catch(notifyError);
    }, [requirements, setRadialbarData, setLoading, tagFilter]);

    return radialbarData?.length === 0 ? (
        <Grid item xs={12}>
            {loading ? <LinearProgress sx={{ mt: 0.25 }} /> : <h5>No data available</h5>}
        </Grid>
    ) : (
        <>
            {radialbarData.map((data) => {
                return (
                    <Grid key={data.id} item xs={4}>
                        <RadialBar data={[data]} indexBy="scope" />
                    </Grid>
                );
            })}
        </>
    );
};

export default DataCompletion;
