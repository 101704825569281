import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Tabs, Tab } from '@mui/material';
import { Form, Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { doSwaggerCall } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';
import { MODAL_TYPE, useModals } from '../../../hooks/useModal';
import RequirementsHierarchy from '../../../components/requirementshierarchy/RequirementsHierarchy';
import { convertFieldToLines, convertLinesToField } from '../../../helpers/defaultFieldHelpers';
import { useAuth } from '../../../hooks/useAuth';
import { ROLES, WARNING_CONTENT_RECALCULATE_TEXT } from '../../../helpers/constans';
import CommonButton from '../../../components/atom/CommonButton';
import IntensityMetricEditor from '../../companies/components/IntensityMetricEditor';
import BasicSettings from '../components/BasicSettings';
import PageWithTitle from '../../../components/atom/PageWithTitle';
import EsgRequirementsHierarchy from '../../../components/esgrequirementshierarchy/EsgRequirementsHierarchy';
import { selectActiveTab, setUrlByTab } from '../helpers/tabHelperFunctions';

function EditSitePage({ newSite }) {
    const navigate = useNavigate();
    const { showModal } = useModals();
    const { notifySuccess, notifyError } = useNotify();
    const { companyId, siteId, type } = useParams();
    const [loading, setLoading] = useState(true);
    const [site, setSite] = useState(null);
    const [checkedRequirementsData, setCheckedRequirementsData] = useState([]);
    const [checkedEsgRequirementsData, setCheckedEsgRequirementsData] = useState([]);
    const { user } = useAuth();
    const isSuperAdmin = user.role === ROLES.SUPERADMIN;
    const [companyYears, setCompanyYears] = useState([]);
    const [localCompanyYear, setLocalCompanyYear] = useState(new Date().getFullYear() - 1); // default year is the previous year
    const [activeTab, setActiveTab] = useState(selectActiveTab(type));

    const defaultSite = {
        name: '',
        location_id: 1,
        tags: [],
        default_field: [],
    };

    const loadSite = useCallback((yearParam, siteParam) => {
        setLoading(true);
        doSwaggerCall('Sites', 'getSite', { id: siteParam, companyId, year: yearParam })
            .then((res) => {
                setSite({
                    id: res.id,
                    name: res.name,
                    location_id: res.location_id,
                    tags: res.tags.map((t) => t.name),
                    default_field: convertFieldToLines(res.default_field),
                });
                setCheckedRequirementsData(res.requirements);
                setCheckedEsgRequirementsData(res.esg_requirements);
                setCompanyYears(res.company_years);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                navigate(`/companies/${companyId}/sites`);
                notifyError(err);
            });
    }, []);

    useEffect(() => {
        if (!newSite) {
            loadSite(localCompanyYear, siteId);
        } else {
            setLoading(false);
        }
    }, [companyId, siteId, localCompanyYear]);

    const onEdit = (values) => {
        showModal(MODAL_TYPE.CONFIRMATION, {
            title: `Are you sure you want to edit this site?`,
            warningContent: WARNING_CONTENT_RECALCULATE_TEXT,
            confirm: () => {
                doSwaggerCall(
                    'Sites',
                    'editSite',
                    { id: siteId, companyId },
                    {
                        name: values.name,
                        locationId: parseInt(values.location_id, 10),
                        tags: values.tags,
                        year: localCompanyYear,
                        requirements: checkedRequirementsData,
                        esgRequirements: checkedEsgRequirementsData,
                        defaultField: convertLinesToField(values.default_field),
                    }
                )
                    .then(() => {
                        loadSite(localCompanyYear, siteId);
                        navigate(`/companies/${companyId}/sites`);
                        notifySuccess('Site updated');
                    })
                    .catch((err) => {
                        notifyError(err);
                    });
            },
        });
    };

    const onSubmit = async (values) => {
        if (!newSite) {
            return onEdit(values);
        }
        try {
            await doSwaggerCall(
                'Sites',
                'addSite',
                { id: companyId },
                {
                    name: values.name,
                    locationId: parseInt(values.location_id, 10),
                    tags: values.tags,
                    year: localCompanyYear,
                    requirements: checkedRequirementsData,
                    defaultField: convertLinesToField(values.default_field),
                }
            );
            navigate(`/companies/${companyId}/sites`);
            notifySuccess('The site has been successfully added to the company');
        } catch (error) {
            notifyError(error);
        }
    };

    if (loading) {
        return true;
    }

    return (
        <Formik onSubmit={onSubmit} initialValues={site || { ...defaultSite }}>
            {({ isSubmitting, values, setFieldValue, touched, errors }) => (
                <Form>
                    <PageWithTitle title={newSite ? 'Add new Site' : 'Edit Site'}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Tabs
                                    value={activeTab}
                                    onChange={(event, newValue) => {
                                        setActiveTab(newValue);
                                        navigate(
                                            `/companies/${companyId}/sites/edit/${siteId}/${setUrlByTab(
                                                newValue
                                            )}`
                                        );
                                    }}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    centered
                                    TabIndicatorProps={{
                                        style: {
                                            // remove underline since position not follows sidebar change
                                            display: 'none',
                                        },
                                    }}
                                >
                                    <Tab label="Basic settings" />
                                    <Tab label="Inventory Boundary" disabled={newSite} />
                                    <Tab label="ESG Inventory Boundary" disabled={newSite} />
                                    <Tab label="Intensity metrics" disabled={newSite} />
                                </Tabs>
                            </Grid>
                            {activeTab !== 3 && (
                                <Grid item container xs={12} justifyContent="flex-end">
                                    <Grid item xs={2} md={2}>
                                        <CommonButton
                                            fullWidth
                                            onClick={() =>
                                                navigate(`/companies/${companyId}/sites`)
                                            }
                                            label="Cancel"
                                            buttonType="secondary"
                                        />
                                    </Grid>
                                    <Grid item xs={2} md={2}>
                                        <CommonButton
                                            fullWidth
                                            disabled={isSubmitting}
                                            type="submit"
                                            label="Save all changes"
                                            buttonType="primary"
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                        {activeTab === 0 && (
                            <BasicSettings
                                values={values}
                                setFieldValue={setFieldValue}
                                touched={touched}
                                errors={errors}
                                companyId={companyId}
                                isSuperAdmin={isSuperAdmin}
                            />
                        )}
                        {activeTab === 1 && (
                            <RequirementsHierarchy
                                localCompanyYear={localCompanyYear}
                                setLocalCompanyYear={setLocalCompanyYear}
                                availableYears={companyYears}
                                checkedData={checkedRequirementsData}
                                setCheckedData={setCheckedRequirementsData}
                                reloadData={() => loadSite(localCompanyYear, siteId)}
                                newSite={newSite}
                            />
                        )}
                        {activeTab === 2 && (
                            <EsgRequirementsHierarchy
                                localCompanyYear={localCompanyYear}
                                setLocalCompanyYear={setLocalCompanyYear}
                                availableYears={companyYears}
                                checkedData={checkedEsgRequirementsData}
                                setCheckedData={setCheckedEsgRequirementsData}
                                reloadData={() => loadSite(localCompanyYear, siteId)}
                                newSite={newSite}
                            />
                        )}
                        {activeTab === 3 && (
                            <Grid item xs={8}>
                                <IntensityMetricEditor
                                    companyId={companyId}
                                    siteId={siteId}
                                    localCompanyYear={localCompanyYear}
                                    setLocalCompanyYear={setLocalCompanyYear}
                                    availableYears={companyYears}
                                />
                            </Grid>
                        )}
                    </PageWithTitle>
                </Form>
            )}
        </Formik>
    );
}

export default EditSitePage;
