import {
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
} from '@mui/material';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SkeletonTable from '../../../components/atom/SkeletonTable';
import { MODAL_TYPE, useModals } from '../../../hooks/useModal';
import { doSwaggerCall } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';
import { useAuth } from '../../../hooks/useAuth';
import {
    ROLES,
    TABLE_ROW_COUNT_OPTIONS,
    WARNING_CONTENT_RECALCULATE_TEXT,
} from '../../../helpers/constans';
import SortableTableCell from '../../../components/atom/SortableTableCell';
import { handleChangeRowsPerPage, handlePageChange } from '../../../helpers/handleTableFunctions';

const SitesTable = ({
    rows,
    loading,
    limit,
    setParams,
    total,
    page,
    companyId,
    reloadData,
    sort,
    sortDir,
    setSorting,
}) => {
    const { showModal } = useModals();
    const { notifyError, notifySuccess } = useNotify();
    const { user } = useAuth();
    const isSuperAdmin = user.role === ROLES.SUPERADMIN;

    const deleteSite = useCallback((id) => {
        doSwaggerCall('Sites', 'deleteSite', { id })
            .then(() => {
                notifySuccess('Site deleted');
                reloadData();
            })
            .catch((err) => {
                notifyError(err);
            });
    }, []);

    return (
        <Grid item xs={12}>
            <TableContainer component={Paper}>
                <Table aria-label="custom table">
                    <TableHead>
                        <TableRow>
                            <SortableTableCell
                                arrow={sort === 'name' ? sortDir : ''}
                                onClick={() => setSorting('name')}
                            >
                                Name
                            </SortableTableCell>
                            <SortableTableCell
                                arrow={sort === 'location' ? sortDir : ''}
                                onClick={() => setSorting('location')}
                            >
                                Location
                            </SortableTableCell>
                            <TableCell width="35%">Tags</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <SkeletonTable rows={limit} cells={4} />
                        ) : (
                            rows?.map((row) => (
                                <TableRow>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell>{row.location_name}</TableCell>
                                    <TableCell>{`${row.tags.map((e) => e).join(', ')}`}</TableCell>
                                    <TableCell align="right">
                                        <Link
                                            to={`/companies/${companyId}/sites/edit/${row.id}/basic`}
                                        >
                                            <Tooltip title="Edit">
                                                <IconButton>
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Link>
                                        {isSuperAdmin && (
                                            <IconButton
                                                onClick={() => {
                                                    showModal(MODAL_TYPE.CONFIRMATION, {
                                                        title: `Are you sure you want to remove this site?`,
                                                        content: 'This action is irreversible.',
                                                        warningContent:
                                                            WARNING_CONTENT_RECALCULATE_TEXT,
                                                        confirm: () => deleteSite(row.id),
                                                    });
                                                }}
                                            >
                                                <Tooltip title="Delete">
                                                    <DeleteIcon />
                                                </Tooltip>
                                            </IconButton>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                count={total || 0}
                rowsPerPage={limit}
                component="div"
                page={page}
                onPageChange={(_, newPage) => handlePageChange(newPage, setParams, limit)}
                rowsPerPageOptions={TABLE_ROW_COUNT_OPTIONS}
                onRowsPerPageChange={(event) => handleChangeRowsPerPage(event, setParams)}
            />
        </Grid>
    );
};

export default SitesTable;
