import React from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';

const GroupByComponent = ({ label = 'Group by', groupBy, setGroupBy, search, groupByOptions }) => {
    return (
        <Grid item xs={2}>
            <FormControl fullWidth>
                <InputLabel id="group-by">{label}</InputLabel>
                <Select
                    fullWidth
                    labelId="group-by"
                    value={groupBy}
                    size="small"
                    label="Group by"
                    onChange={(e) => {
                        setGroupBy(e.target.value);
                    }}
                    disabled={search !== ''}
                >
                    {Object.entries(groupByOptions).map(([key, value]) => (
                        <MenuItem value={key} key={key}>
                            {value}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Grid>
    );
};

export default GroupByComponent;
