import { Autocomplete, FormControl, Grid, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import EsgFieldsTable from './EsgFieldsTable';
import { doSwaggerCall } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';

// Get questions from ESG scenario target hierarchy
function getEsgQuestionsFromScenario(targetHierarchy) {
    const questions = [];

    targetHierarchy.forEach((target) => {
        if (target.type === 'question') {
            questions.push({ id: target.id, name: target.name, fields: target.children });
        } else if (target.children && target.children.length > 0) {
            questions.push(...getEsgQuestionsFromScenario(target.children));
        }
    });

    return questions;
}

// Set only numeric fields
function setOnlyNumericFields(fields) {
    const uniqueFields = new Map();
    fields
        .flatMap((field) =>
            field.values
                .filter((value) => value.type === 'number')
                .map((numericValue) => ({
                    id: numericValue.id || '',
                    label: numericValue.question || '',
                    measure: numericValue.measure || '',
                }))
        )
        .forEach((field) => {
            if (field.id && !uniqueFields.has(field.id)) {
                uniqueFields.set(field.id, field);
            }
        });
    return Array.from(uniqueFields.values());
}

const EsgScenarioQuestionSelector = ({ panelConfig, setPanelConfig }) => {
    const [esgQuestions, setEsgQuestions] = useState([]);
    const { notifyError } = useNotify();
    const [esgFields, setEsgFields] = useState(panelConfig?.fields || []);

    const getEsgScenario = async () => {
        try {
            const scenario = await doSwaggerCall('EsgScenarios', 'getEsgScenario', {
                id: panelConfig.scenario,
            });
            const questions = getEsgQuestionsFromScenario(scenario.target_hierarchy);
            setEsgQuestions(questions);

            // Set numeric fields only when ESG questions are initially loaded
            const allNumericFields = questions.flatMap((question) =>
                setOnlyNumericFields(question.fields || [])
            );
            setEsgFields(allNumericFields);
        } catch (err) {
            notifyError(err);
        }
    };

    useEffect(() => {
        if (panelConfig.scenario) {
            getEsgScenario();
        }
    }, [panelConfig.scenario]);

    return (
        <>
            <Grid item xs={12}>
                {esgQuestions.length > 0 && panelConfig?.scenario ? (
                    <FormControl fullWidth>
                        <Autocomplete
                            options={esgQuestions}
                            getOptionLabel={(option) => `${option.name}`}
                            value={
                                esgQuestions.find(
                                    (question) => question.id === panelConfig?.esgQuestion
                                ) || null
                            }
                            onChange={(_, newValue) => {
                                setPanelConfig({
                                    ...panelConfig,
                                    esgQuestion: newValue?.id,
                                    fields: setOnlyNumericFields(newValue?.fields || []),
                                });
                                setEsgFields(setOnlyNumericFields(newValue?.fields || []));
                            }}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            renderInput={(params) => <TextField {...params} label="ESG question" />}
                        />
                    </FormControl>
                ) : null}
            </Grid>
            {panelConfig?.esgQuestion && panelConfig?.scenario ? (
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <EsgFieldsTable
                        esgFields={esgFields}
                        panelConfig={panelConfig}
                        setPanelConfig={setPanelConfig}
                    />
                </Grid>
            ) : null}
        </>
    );
};

export default EsgScenarioQuestionSelector;
