import React, { useState } from 'react';
import {
    DialogActions,
    DialogContentText,
    DialogContent,
    DialogTitle,
    Dialog,
    Grid,
    TextField,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import { doSwaggerCall } from '../hooks/useApi';
import useNotify from '../hooks/useNotify';
import CommonButton from '../components/atom/CommonButton';
import FreeSoloEsgTagAutoComplete from '../components/atom/FreeSoloEsgTagAutoComplete';
import convertEsgTags from '../pages/esgedit/helpers/convertEsgTags';

function successMessage(type) {
    if (type === 'addNote') {
        return 'Notes modified';
    }
    if (type === 'assignTags') {
        return 'Tags modified';
    }
    return 'Estimated value modified';
}

const BulkEditEsgsModal = ({
    title,
    content,
    showModal,
    MODAL_TYPE,
    selectedLines,
    setSelectedLines,
    reloadData,
    type,
}) => {
    const closeAction = () => showModal(MODAL_TYPE.NONE);
    const [notes, setNotes] = useState(null);
    const [estimated, setEstimated] = useState(null);
    const { notifyError, notifySuccess } = useNotify();
    const [percentages, setPercentages] = useState([]);
    const [tags, setTags] = useState([]);

    const isNote = type === 'addNote';
    const isTag = type === 'assignTags';

    const setData = async () => {
        try {
            const params = {
                idList: selectedLines,
                isNote: !!isNote,
                isTag: !!isTag,
            };
            const body = {};
            if (isTag) {
                body.esgTagList = convertEsgTags(tags, percentages);
            }
            if (isNote) {
                body.note = notes;
            } else {
                params.estimated = estimated;
            }
            await doSwaggerCall(
                'Esgs',
                'editBulkEsgs',
                {
                    ...params,
                },
                { ...body }
            );
            notifySuccess(successMessage(type));
            reloadData();
            setSelectedLines([]);
        } catch (err) {
            notifyError(err);
        }
    };

    function selectModalType(value) {
        switch (value) {
            case 'addNote':
                return (
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Notes"
                            multiline
                            autoComplete="off"
                            type="text"
                            value={notes}
                            onChange={(ev) => setNotes(ev.target.value)}
                        />
                    </Grid>
                );
            case 'setEstimated':
                return (
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={!!estimated}
                                    onChange={(e) => setEstimated(!!e.target.checked)}
                                />
                            }
                            label="Estimated value"
                            labelPlacement="end"
                        />
                    </Grid>
                );
            case 'assignTags':
                return (
                    <FreeSoloEsgTagAutoComplete
                        fullWidth
                        label="Tags"
                        value={tags}
                        setValue={setTags}
                        percentages={percentages}
                        setPercentages={setPercentages}
                        closedYear={false} // can't reach this point if year is closed
                    >
                        <TextField label="ESG tags" required />
                    </FreeSoloEsgTagAutoComplete>
                );
            default:
                return null;
        }
    }

    return (
        <Dialog
            open
            onClose={closeAction}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
                <Grid>{selectModalType(type)}</Grid>
            </DialogContent>
            <DialogActions>
                <CommonButton
                    onClick={closeAction}
                    autoFocus
                    label="Cancel"
                    buttonType="secondary"
                />
                <CommonButton
                    onClick={() => {
                        setData();
                        showModal(MODAL_TYPE.NONE);
                    }}
                    label="Yes"
                    buttonType="primary"
                />
            </DialogActions>
        </Dialog>
    );
};

export default BulkEditEsgsModal;
