import { Alert, Autocomplete, FormControl, Grid, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import BlockWithTitle from '../../../components/atom/BlockWithTitle';

const AdvancedSettings = ({ values, setFieldValue }) => {
    const [industries, setIndustries] = useState([]);

    useEffect(() => {
        fetch('/assets/industries.json')
            .then((response) => response.json())
            .then((data) => {
                setIndustries([...new Set(data)]);
            });
    }, []);

    return (
        <Grid item xs={8}>
            <BlockWithTitle title="Advanced settings">
                <Grid item xs={12}>
                    <Alert severity="info">
                        These settings are optional and will be provided to ChatGPT to fine-tune the
                        AI-generated responses.
                    </Alert>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <Autocomplete
                            options={industries || []}
                            freeSolo
                            getOptionLabel={(option) => option}
                            renderInput={(params) => <TextField {...params} label="Industry" />}
                            onInputChange={(_, newValue) => {
                                setFieldValue('ai_finetune.industry', newValue);
                            }}
                            value={values?.ai_finetune?.industry}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        multiline
                        inputProps={{ maxLength: 300 }}
                        autoComplete="off"
                        label="Short company description"
                        value={values?.ai_finetune?.short_description || ''}
                        onChange={(e) =>
                            setFieldValue('ai_finetune.short_description', e.target.value)
                        }
                    />
                </Grid>
            </BlockWithTitle>
        </Grid>
    );
};

export default AdvancedSettings;
