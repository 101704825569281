import React from 'react';
import { Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useDarkMode } from '../../hooks/useDarkMode';

const InfoIconComponent = ({ showForceOverride }) => {
    const { darkMode } = useDarkMode();
    return (
        showForceOverride === true && (
            <Tooltip
                title="Calculated value based on GHG data"
                sx={{ cursor: 'pointer' }}
                placement="top"
            >
                <InfoIcon
                    style={{
                        fontSize: '15px',
                        color: !darkMode ? 'grey' : 'white',
                        marginRight: '10px',
                    }}
                />
            </Tooltip>
        )
    );
};

export default InfoIconComponent;
