export function selectActiveTab(tabType) {
    switch (tabType) {
        case 'basic':
            return 0;
        case 'inventory':
            return 1;
        case 'esginventory':
            return 2;
        case 'intensity':
            return 3;
        case 'years':
            return 4;
        case 'advanced':
            return 5;
        default:
            return 0;
    }
}

export function setUrlByTab(tab) {
    switch (tab) {
        case 0:
            return 'basic';
        case 1:
            return 'inventory';
        case 2:
            return 'esginventory';
        case 3:
            return 'intensity';
        case 4:
            return 'years';
        case 5:
            return 'advanced';
        default:
            return 'basic';
    }
}
