import {
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Tooltip,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import FloatInput from '../../../components/atom/FloatInput';
import { useAuth } from '../../../hooks/useAuth';
import { PERMISSIONS, ROLES } from '../../../helpers/constans';
import AirportDistanceCalculator from './AirportDistanceCalculator';
import { AIRPORT_DISTANCE_CALCULATION } from '../../../helpers/checkAirportCalculatorVisibility';
import AirportDistanceFormula from './AirportDistanceFormula';

function getSelectedTab(calculationType, conversionLogic) {
    // default logic (or base unit) is always the first tab
    if (calculationType === null || !conversionLogic) {
        return 0;
    }
    const logicIds = conversionLogic.map((e) => `${e.id}`);
    const selected = logicIds.indexOf(`${calculationType}`);
    if (selected === -1) {
        return 0;
    }
    return selected + 1;
}
function DatalineFormulaPickerDropdown({
    conversionLogic,
    fields,
    setFieldValue,
    calculationType,
    setCalculationType,
    baseUnit,
    amount,
    setAmount,
    defaultField,
    // disables the "base unit" option
    noBaseUnit = false,
    // system managed
    systemManagedDataline = false,
    systemManagedEmissionSource = false,
    closedYear = false,
    isAirportCalculatorVisible,
    notes = null,
    setShowNotes,
    setDisabledByAirportCalculator,
}) {
    const { user, userPermissions } = useAuth();
    const basicModifyDatalinePermission = userPermissions.includes(
        PERMISSIONS.BASIC_MODIFY_DATALINES
    );
    const complexModifyDatalinePermission = userPermissions.includes(
        PERMISSIONS.COMPLEX_MODIFY_DATALINES
    );
    const manageScenariosPermission = userPermissions.includes(PERMISSIONS.MANAGE_SCENARIOS);
    const [showCalculator, setShowCalculator] = useState(false);

    const setFields = useCallback(
        (newValue) => {
            setFieldValue('fields', newValue);
        },
        [setFieldValue]
    );
    // pick the one logic that is currently used
    const selectedLogic = getSelectedTab(calculationType, conversionLogic);
    const setSelectedLogic = useCallback(
        (tab) => {
            if (tab === 0) {
                // base unit one
                return setCalculationType(null);
            }
            return setCalculationType(conversionLogic[tab - 1].id);
        },
        [conversionLogic, setCalculationType, baseUnit]
    );

    // handle a case where there should not be a base unit
    useEffect(() => {
        if (noBaseUnit === false || calculationType !== null) {
            return;
        }
        if (conversionLogic.length === 0) {
            return;
        }
        setCalculationType(conversionLogic[1]?.id);
    }, [noBaseUnit, calculationType, setCalculationType]);

    useEffect(() => {
        // if empty all the fields, prefill it
        if (selectedLogic === 0) {
            if (defaultField?.default_field[baseUnit]) {
                setAmount(defaultField?.default_field[baseUnit]);
            }
            return;
        }

        // all fields should be ...
        const newFields = { ...fields };
        conversionLogic[selectedLogic - 1]?.fields.forEach((oneField) => {
            const hasDefault = defaultField?.default_field[oneField.label];
            if (!hasDefault) {
                return;
            }
            newFields[oneField.field_id] = hasDefault;
        });
        setFields(newFields);
    }, [defaultField?.default_field, setFields, selectedLogic, baseUnit]);

    if (!baseUnit) {
        return <span>Pick an emission source first!</span>;
    }
    if (conversionLogic.length === 0 && noBaseUnit) {
        return <span>No conversion logic available</span>;
    }
    return (
        <Grid container spacing={2} style={{ alignItems: 'center' }}>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <InputLabel id="logic-select">Unit of measurements</InputLabel>
                    <Select
                        fullWidth
                        labelId="logic-select"
                        id="demo-simple-select"
                        value={selectedLogic || 0}
                        label="Unit of measurements"
                        onChange={(e) => {
                            setSelectedLogic(e.target.value);
                        }}
                        disabled={
                            (!basicModifyDatalinePermission &&
                                !complexModifyDatalinePermission &&
                                !manageScenariosPermission) ||
                            systemManagedDataline ||
                            systemManagedEmissionSource ||
                            closedYear
                        }
                    >
                        {!noBaseUnit && (
                            <MenuItem value={0}>
                                {user.role === ROLES.SUPERADMIN
                                    ? `${baseUnit} (base unit)`
                                    : baseUnit}
                            </MenuItem>
                        )}
                        {conversionLogic.map((logic, idx) => {
                            return (
                                <MenuItem key={idx} value={idx + 1}>
                                    {logic?.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={9}>
                <Grid container>
                    {!noBaseUnit && selectedLogic === 0 && (
                        <>
                            <Grid
                                item
                                xs={
                                    baseUnit === AIRPORT_DISTANCE_CALCULATION &&
                                    isAirportCalculatorVisible
                                        ? 11
                                        : 12
                                }
                            >
                                <FloatInput
                                    fullWidth
                                    label="Usage"
                                    type="number"
                                    autoComplete="off"
                                    required
                                    value={amount}
                                    onChange={(ev) => {
                                        setAmount(ev.target.rawValue);
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {baseUnit}
                                            </InputAdornment>
                                        ),
                                    }}
                                    disabled={
                                        (!basicModifyDatalinePermission &&
                                            !complexModifyDatalinePermission) ||
                                        systemManagedDataline ||
                                        systemManagedEmissionSource ||
                                        closedYear
                                    }
                                />
                            </Grid>
                            {isAirportCalculatorVisible &&
                                baseUnit === AIRPORT_DISTANCE_CALCULATION && (
                                    <Grid
                                        item
                                        xs={1}
                                        sx={{ alignSelf: 'center', textAlign: 'center' }}
                                    >
                                        <Tooltip title="Distance calculator">
                                            <IconButton
                                                onClick={() => {
                                                    setShowCalculator(!showCalculator);
                                                }}
                                            >
                                                {showCalculator ? (
                                                    <ArrowDropUpIcon />
                                                ) : (
                                                    <ArrowDropDownIcon />
                                                )}
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                )}
                        </>
                    )}
                    {selectedLogic !== 0 &&
                        conversionLogic[selectedLogic - 1]?.name === 'Airport Distance' && (
                            <AirportDistanceFormula
                                logic={conversionLogic[selectedLogic - 1]}
                                fields={fields}
                                setFields={setFields}
                                setDisabledByAirportCalculator={setDisabledByAirportCalculator}
                                closedYear={closedYear}
                            />
                        )}
                    {selectedLogic !== 0 &&
                        conversionLogic[selectedLogic - 1]?.name !== 'Airport Distance' &&
                        conversionLogic[selectedLogic - 1]?.fields.map((oneField) => {
                            return (
                                <Grid
                                    item
                                    key={oneField.id}
                                    xs={12 / conversionLogic[selectedLogic - 1].fields.length}
                                >
                                    <FloatInput
                                        fullWidth
                                        label={oneField.label}
                                        type="number"
                                        autoComplete="off"
                                        required
                                        value={fields[oneField.field_id] || ''}
                                        onChange={(ev) => {
                                            setFields({
                                                ...fields,
                                                [oneField.field_id]: ev.target.rawValue,
                                            });
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    {oneField.prefix}
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {oneField.postfix}
                                                </InputAdornment>
                                            ),
                                        }}
                                        disabled={
                                            (!basicModifyDatalinePermission &&
                                                !complexModifyDatalinePermission) ||
                                            systemManagedDataline ||
                                            systemManagedEmissionSource ||
                                            closedYear
                                        }
                                    />
                                </Grid>
                            );
                        })}
                </Grid>
            </Grid>
            {baseUnit === AIRPORT_DISTANCE_CALCULATION &&
                selectedLogic === 0 &&
                showCalculator &&
                isAirportCalculatorVisible && (
                    <Grid item xs={12}>
                        <AirportDistanceCalculator
                            closedYear={closedYear}
                            setAmount={setAmount}
                            setFieldValue={setFieldValue}
                            notes={notes}
                            setShowNotes={setShowNotes}
                        />
                    </Grid>
                )}
        </Grid>
    );
}

export default DatalineFormulaPickerDropdown;
